import React, {useMemo} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AppBadge from '@common/core/AppBadge';
import Link from 'next/link';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IntlMessages from '../../../../../utility/IntlMessages';
import {
  checkPathPermission,
  checkPermission,
} from '../../../../../utility/helper/RouteHelper';
import {useAuthUser} from '../../../../../utility/AuthHooks';
import VerticalNavItem from './VerticalNavItem';

import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import {useSelector} from 'react-redux';

import {useIntl} from 'react-intl';
const VerticalItem = ({level, router, item}) => {
  const {user} = useAuthUser();
  const intl = useIntl();
  const permission = useSelector(
    ({authentication}) => authentication.permission,
  );

  const isSuperAdmin = useSelector(
    ({authentication}) => authentication.isSuperAdmin,
  );

  const hasPermission = useMemo(
    // eslint-disable-next-line react/prop-types
    () =>
      !isSuperAdmin
        ? // eslint-disable-next-line react/prop-types
          checkPathPermission(item.url, permission, item.permission)
        : true,
    // eslint-disable-next-line react/prop-types
    [item.url, permission],
  );

  if (!hasPermission) {
    return null;
  }

  return !item.hidden ? (
    <Link href={item.url} as={item.as}>
      <a style={{textDecoration: 'none'}}>
        <VerticalNavItem
          item={item}
          level={level}
          router={router}
          exact={item.exact}
        >
          {item.icon && (
            <Box component='span'>
              <Icon
                sx={{
                  fontSize: 18,
                  display: 'block',
                  mr: 4,
                }}
                className={clsx('nav-item-icon', 'material-icons-outlined')}
                color='action'
              >
                {item.icon}
              </Icon>
            </Box>
          )}
          <Tooltip
            title={<IntlMessages id={item.messageId} />}
            placement={'right'}
            arrow
          >
            <ListItemText
              className='nav-item-content'
              primary={
                intl.formatMessage({id: item.messageId}).length > 26
                  ? intl
                      .formatMessage({id: item.messageId})
                      .substring(0, 23)
                      .concat('...')
                  : intl.formatMessage({id: item.messageId})
              }
              classes={{primary: 'nav-item-text'}}
            />
          </Tooltip>

          {item.count && (
            <Box sx={{mr: 3.5}} className='menu-badge'>
              <AppBadge count={item.count} color={item.color} />
            </Box>
          )}
        </VerticalNavItem>
      </a>
    </Link>
  ) : null;
};

VerticalItem.propTypes = {
  item: PropTypes.shape({
    as: PropTypes.string,
    color: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    exact: PropTypes.bool,
    hidden: PropTypes.any,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    id: PropTypes.string.isRequired,
    messageId: PropTypes.string,
    permittedRole: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  level: PropTypes.number,
  router: PropTypes.object,
};

VerticalItem.defaultProps = {};

export default React.memo(VerticalItem);
