import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useState, useRef} from 'react';
const AppFastMarquee = ({
  style = {},
  className = '',
  play = true,
  pauseOnHover = false,
  pauseOnClick = false,
  direction = 'left',
  speed = 20,
  delay = 0,
  loop = 0,
  gradient = true,
  gradientColor = [255, 255, 255],
  gradientWidth = 200,
  onFinish,
  onCycleComplete,
  children,
}) => {
  // React Hooks
  const [containerWidth, setContainerWidth] = useState(0);
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const containerRef = useRef(null);
  const marqueeRef = useRef(null);
  useEffect(() => {
    if (!isMounted) return;
    const calculateWidth = () => {
      if (marqueeRef.current && containerRef.current) {
        setContainerWidth(containerRef.current.getBoundingClientRect().width);
        setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width);
      }
    };
    calculateWidth();
    window.addEventListener('resize', calculateWidth);
    return () => {
      window.removeEventListener('resize', calculateWidth);
    };
  }, [isMounted]);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  const rgbaGradientColor = `rgba(${gradientColor[0]}, ${gradientColor[1]}, ${gradientColor[2]}`;
  const duration =
    marqueeWidth < containerWidth
      ? containerWidth / speed
      : marqueeWidth / speed;

  return (
    <Fragment>
      {!isMounted ? null : (
        <div
          ref={containerRef}
          style={{
            ...style,
            ['--pause-on-hover']: !play || pauseOnHover ? 'paused' : 'running',
            ['--pause-on-click']:
              !play || (pauseOnHover && !pauseOnClick) || pauseOnClick
                ? 'paused'
                : 'running',
          }}
          className={className + ' marquee-container'}
        >
          {gradient && (
            <div
              style={{
                ['--gradient-color']: `${rgbaGradientColor}, 1), ${rgbaGradientColor}, 0)`,
                ['--gradient-width']:
                  typeof gradientWidth === 'number'
                    ? `${gradientWidth}px`
                    : gradientWidth,
              }}
              className='overlay'
            />
          )}
          <div
            ref={marqueeRef}
            style={{
              ['--play']: play ? 'running' : 'paused',
              ['--direction']: direction === 'left' ? 'normal' : 'reverse',
              ['--duration']: `${duration}s`,
              ['--delay']: `${delay}s`,
              ['--iteration-count']: !!loop ? `${loop}` : 'infinite',
            }}
            className='marquee'
            onAnimationIteration={onCycleComplete}
            onAnimationEnd={onFinish}
          >
            {children}
          </div>
          <div
            style={{
              ['--play']: play ? 'running' : 'paused',
              ['--direction']: direction === 'left' ? 'normal' : 'reverse',
              ['--duration']: `${duration}s`,
              ['--delay']: `${delay}s`,
              ['--iteration-count']: !!loop ? `${loop}` : 'infinite',
            }}
            className='marquee'
            aria-hidden='true'
          >
            {children}
          </div>
        </div>
      )}
    </Fragment>
  );
};

AppFastMarquee.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  delay: PropTypes.number,
  direction: PropTypes.string,
  gradient: PropTypes.bool,
  gradientColor: PropTypes.array,
  gradientWidth: PropTypes.number,
  loop: PropTypes.number,
  onCycleComplete: PropTypes.any,
  onFinish: PropTypes.any,
  pauseOnClick: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  play: PropTypes.bool,
  speed: PropTypes.number,
  style: PropTypes.object,
};
export default AppFastMarquee;
