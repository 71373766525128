import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import HeaderNavWrapper from './HeaderNavWrapper';
import HorizontalNav from '../../components/HorizontalNav';
import {useSidebarActionsContext} from '@common/utility/AppContextProvider/SidebarContextProvider';
import defaultConfig, {
  LightSidebarSticky,
} from '@common/utility/AppContextProvider/defaultConfig';
import {ElementSection} from 'shared/constants/AppEnums';

const AppHeader = () => {
  const top = 260;
  const {updateSidebarColorSet} = useSidebarActionsContext();
  const [visible, setVisible] = useState(false);
  const layoutClient = useSelector(
    ({layoutClientApp}) => layoutClientApp.layoutClientList,
  );
  useEffect(() => {
    const onScroll = () => {
      if (document.documentElement.scrollTop >= top)
        updateSidebarColorSet(LightSidebarSticky);
      else updateSidebarColorSet(defaultConfig.sidebar.colorSet);
      setVisible(document.documentElement.scrollTop >= top);
    };
    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Box>
      <AppBar
        color='inherit'
        position='relative'
        sx={{
          boxShadow: 'none',
          // borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          backgroundColor: 'background.default',
          width: {
            xs: '100%',
          },
          filter:
            Array.isArray(layoutClient) &&
            layoutClient.length > 0 &&
            layoutClient.filter(
              (x) =>
                x.sectionName === ElementSection.GRAYBACKGROUND && x.isDisplay,
            ).length > 0
              ? 'grayscale(100%)'
              : 'unset',
          // borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
        className='app-bar'
      >
        <Hidden lgDown>
          <HeaderNavWrapper>
            <Box
              sx={{
                width: '100%',
                mx: 'auto',
              }}
            >
              <HorizontalNav visible={visible} />
            </Box>
          </HeaderNavWrapper>
        </Hidden>
      </AppBar>
      {visible && (
        <Hidden lgDown>
          <AppBar
            color='inherit'
            position='fixed'
            sx={{
              boxShadow: 'none',
              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
              backgroundColor: 'background.paper',
              width: {
                xs: '100%',
              },
              mx: 'auto',
              filter:
                Array.isArray(layoutClient) &&
                layoutClient.length > 0 &&
                layoutClient.filter(
                  (x) =>
                    x.sectionName === ElementSection.GRAYBACKGROUND &&
                    x.isDisplay,
                ).length > 0
                  ? 'grayscale(100%)'
                  : 'unset',
            }}
            className='app-bar'
          >
            <HeaderNavWrapper>
              <Box
                sx={{
                  width: '100%',
                  mx: 'auto',
                }}
              >
                <HorizontalNav visible={visible} />
              </Box>
            </HeaderNavWrapper>
          </AppBar>
        </Hidden>
      )}
    </Box>
  );
};

AppHeader.propTypes = {};
export default AppHeader;
