import React, {Fragment, useEffect} from 'react';
// eslint-disable-next-line no-restricted-imports
import orange from '@mui/material/colors/orange';
import {useAuthMethod} from '../../../../utility/AuthHooks';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Fonts, PermissionCode} from '../../../../../shared/constants/AppEnums';
import PropTypes from 'prop-types';
import router, {useRouter} from 'next/router';
import {useDispatch, useSelector} from 'react-redux';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import Iconify from '@common/core/Iconify';
import {onGetCountApproval} from 'redux/actions';
import {checkPermissionClient} from '@common/utility/helper/RouteHelper';
import {onGetQuestionNeedProcess} from 'redux/actions/guess/Question';

const UserInfo = ({color, isView, isHome, isSticky, isClient}) => {
  const {logout} = useAuthMethod();
  const dispatch = useDispatch();
  const history = useRouter();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isCall, setCall] = React.useState(false);
  const userInfo = useSelector(({authentication}) => authentication.user);
  const authentication = useSelector(({authentication}) => authentication);
  const count = useSelector(({articleApp}) => articleApp.countArticleApproval);
  const listBlog = useSelector(
    ({lawDisseminationApp}) => lawDisseminationApp.listBlog,
  );
  const questionNeedProcessCount = useSelector(
    ({questionApp}) => questionApp.questionNeedProcessCount,
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => {
    if (userInfo.hoTen) {
      return userInfo.hoTen.charAt(0).toUpperCase();
    }
    if (userInfo.tenDangNhap) {
      return userInfo.tenDangNhap.charAt(0).toUpperCase();
    }
  };

  useEffect(() => {
    if (!isCall && !isView) {
      if (
        authentication.isSuperAdmin ||
        (userInfo &&
          userInfo.isNhanVien &&
          checkPermissionClient(
            authentication.permission,
            PermissionCode.APPLY,
            '/admin/tin-bai',
          ) &&
          checkPermissionClient(
            authentication.permission,
            PermissionCode.READ,
            '/admin/tin-bai',
          ))
      ) {
        dispatch(onGetCountApproval());
      }
      if (
        authentication.isSuperAdmin ||
        (userInfo &&
          userInfo.isNhanVien &&
          checkPermissionClient(
            authentication.permission,
            PermissionCode.APPLY,
            '/admin/cau-hoi',
          ))
      ) {
        dispatch(onGetQuestionNeedProcess());
      }
      setCall(true);
    }
  }, [userInfo, authentication]);

  if (!userInfo) return null;

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          py: isHome ? 0 : 3,
          px: 3,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        className='user-info-view'
      >
        <Box sx={{py: isHome ? 0 : 0.5}}>
          {userInfo?.avatarPath ? (
            <Avatar
              sx={{
                height: 40,
                width: 40,
                fontSize: 24,
                backgroundColor: orange[500],
              }}
              src={userInfo.avatarPath}
            />
          ) : (
            <Avatar
              sx={{
                height: 40,
                width: 40,
                fontSize: 24,
                backgroundColor: orange[500],
              }}
            >
              {getUserAvatar()}
            </Avatar>
          )}
        </Box>
        <Box
          sx={{
            // width: {xs: 'calc(100% - 62px)', xl: 'calc(100% - 72px)'},
            ml: 4,
            color: color,
          }}
          className='user-info'
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                mb: 0,
                maxWidth: 190,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: 13,
                fontWeight: Fonts.MEDIUM,
                color: isSticky ? 'white' : 'inherit',
              }}
              component='span'
            >
              {userInfo.hoTen}
            </Box>
            {!isView && (
              <Box
                sx={{
                  ml: 3,
                  color: isSticky ? 'white' : 'inherit',
                  display: 'flex',
                }}
              >
                <ExpandMoreIcon />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              mt: -0.5,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: isSticky ? 'white' : 'inherit',
            }}
          >
            {userInfo.maNguoiDung}
          </Box>
        </Box>
      </Box>
      {!isView && (
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            boxShadow: (theme) => theme.customShadows.z1,
          }}
        >
          <Divider />
          <MenuItem
            onClick={() => {
              handleClose();
              history.push(
                `${
                  isClient
                    ? '/thong-tin-tai-khoan'
                    : '/admin/thong-tin-tai-khoan'
                }`,
              );
            }}
          >
            <Iconify
              icon={'bx:user-circle'}
              height={24}
              width={24}
              sx={{mr: 2}}
            />
            Thông tin tài khoản
          </MenuItem>
          {listBlog &&
            listBlog.find((x) => x.tenDanhMuc === 'PHẢN ÁNH KIẾN NGHỊ') && (
              <>
                <Divider />
                <MenuItem
                  onClick={() => {
                    handleClose();
                    history.push(`/phan-anh/${userInfo.tenDangNhap}`);
                  }}
                >
                  <Iconify
                    icon={'bxs:comment-error'}
                    height={24}
                    width={24}
                    sx={{mr: 2}}
                  />
                  Phản ánh kiến nghị
                </MenuItem>
              </>
            )}

          {(authentication.isSuperAdmin ||
            (userInfo && userInfo.isNhanVien)) && (
            <Box>
              <Divider />
              <MenuItem onClick={() => router.push('/duyet-tin-bai')}>
                <Iconify
                  icon={'material-symbols:approval-rounded'}
                  height={24}
                  width={24}
                  sx={{mr: 2}}
                />
                <Badge color='secondary' badgeContent={count}>
                  Duyệt tin bài
                </Badge>
              </MenuItem>
            </Box>
          )}
          {(authentication.isSuperAdmin ||
            (userInfo && userInfo.isNhanVien)) && (
            <Box>
              <Divider />
              <MenuItem
                onClick={() => router.push('/hoi-dap/cau-hoi-can-xu-ly')}
              >
                <Iconify
                  icon={'mdi:question-answer'}
                  height={24}
                  width={24}
                  sx={{mr: 2}}
                />
                <Badge
                  color='secondary'
                  badgeContent={questionNeedProcessCount?.totalCount}
                >
                  Hỏi đáp trực tuyến
                </Badge>
              </MenuItem>
            </Box>
          )}
          {(authentication.isSuperAdmin ||
            (userInfo && userInfo.isNhanVien)) && (
            <Box>
              <Divider />
              <MenuItem
                onClick={() =>
                  router.push(router.asPath.includes('/admin') ? '/' : '/admin')
                }
              >
                <Iconify
                  icon={'ooui:article-redirect-ltr'}
                  height={24}
                  width={24}
                  sx={{mr: 2}}
                />
                {router.asPath.includes('/admin')
                  ? 'Truy cập trang chủ'
                  : 'Truy cập trang admin'}
              </MenuItem>
            </Box>
          )}
          <Divider />
          <MenuItem onClick={logout}>
            <Iconify icon={'uim:signout'} height={24} width={24} sx={{mr: 2}} />
            Đăng xuất
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default React.memo(UserInfo);

UserInfo.defaultProps = {
  color: 'text.secondary',
  isView: false,
  isHome: false,
  isSticky: false,
  isClient: false,
};

UserInfo.propTypes = {
  color: PropTypes.string,
  isView: PropTypes.bool,
  isHome: PropTypes.bool,
  isSticky: PropTypes.bool,
  isClient: PropTypes.bool,
};
