import PropTypes from 'prop-types';
import React from 'react';
import HorizontalGroup from './HorizontalGroup';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalItem from './HorizontalItem';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import {useSelector} from 'react-redux';
import MenuHomeSkeleton from '@common/core/AppSkeleton/MenuHomeSkeleton';
import {AiFillHome} from 'react-icons/ai';
import Box from '@mui/material/Box';
import Image from '@common/core/AppMuiImage';
const HorizontalNav = ({visible}) => {
  const dataFooter = useSelector(({customerApp}) => customerApp.dataFooter);

  const dataRouteHome = useSelector(
    ({categoryApp}) => categoryApp.treeCategories.data,
  );
  const categoryHome = {
    id: 1,
    title: 'TRANG CHỦ',
    messageId: 'sidebar',
    type: 'group',
    url: '/',
    children: null,
    icon: <AiFillHome />,
  };

  if (!dataRouteHome)
    return (
      <List className='navbarNav'>
        <MenuHomeSkeleton />
      </List>
    );

  return (
    <List className='navbarNav'>
      {!!visible && (
        <Box sx={{px: 2}}>
          <Image
            fit={'contain'}
            src={dataFooter.logoFilePath}
            height={32}
            width={40}
          />
        </Box>
      )}
      <HorizontalGroup item={categoryHome} nestedLevel={0} />
      {dataRouteHome.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && (
            <HorizontalGroup item={item} nestedLevel={0} />
          )}

          {item.type === 'collapse' && (
            <HorizontalCollapse item={item} nestedLevel={0} />
          )}

          {item.type === 'item' && (
            <HorizontalItem item={item} nestedLevel={0} />
          )}

          {item.type === 'divider' && <Divider sx={{my: 5}} />}
        </React.Fragment>
      ))}
    </List>
  );
};

HorizontalNav.propTypes = {
  visible: PropTypes.bool,
};

export default HorizontalNav;
