import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Slider from 'react-slick';
import CarouselArrowIndex from './atom/CarouselArrowIndex';
import useTheme from '@mui/styles/useTheme';
import {useRef, useState} from 'react';
import Image from '../LazyLoadImage';

export default function CarouselBasic({_carousels}) {
  const theme = useTheme();

  const carouselRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(
    theme.direction === 'rtl' ? _carousels.length - 1 : 0,
  );

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Card sx={{position: 'relative'}}>
      <Slider ref={carouselRef} {...settings}>
        {_carousels.map((item, index) => (
          <CarouselItem key={index} item={item} />
        ))}
      </Slider>

      <CarouselArrowIndex
        index={currentIndex}
        total={_carousels.length}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </Card>
  );
}

CarouselBasic.propTypes = {
  _carousels: PropTypes.array,
};

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.any,
};

function CarouselItem({item}) {
  return <Image src={item} sx={{maxHeight: 225}} />;
}
