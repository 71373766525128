import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from 'next/link';
import Image from '@common/core/AppMuiImage';

const AppLogo = (props) => {
  const {height, width, src} = props;
  return (
    <Link href={'/'}>
      <Box
        sx={{
          padding: 1,
          display: 'flex',
          flexDirection: 'row',
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className='app-logo'
      >
        <Image src={src} height={height} width={width} errorIcon />
      </Box>
    </Link>
  );
};

AppLogo.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  src: PropTypes.string,
};

export default AppLogo;

AppLogo.defaultProps = {
  height: 120,
  width: 150,
  src: '/assets/images/logo-cong-an.png',
};
