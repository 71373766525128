import React, {Fragment} from 'react';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import {Fonts} from 'shared/constants/AppEnums';
import IconButton from '@common/core/AppIconButton';
import Iconify from '@common/core/Iconify';
import UserInfo from '../../components/UserInfo';
import AppWeather from '@common/core/AppWeather';
import AppNotificationsReflect from '../../../AppNotificationsReflect';
import Link from 'next/link';
import Hidden from '@mui/material/Hidden';
import AppFastMarquee from '@common/core/AppFastMarquee';
import {ElementSection} from 'shared/constants/AppEnums';

const NotificationBar = () => {
  const isAuthenticated = useSelector(
    ({authentication}) => authentication.isAuthenticated,
  );
  const listBlog = useSelector(
    ({lawDisseminationApp}) => lawDisseminationApp.listBlog,
  );
  const activities = useSelector(
    ({activityApp}) => activityApp.activitiesClient,
  );

  const layoutClient = useSelector(
    ({layoutClientApp}) => layoutClientApp.layoutClientList,
  );

  return (
    <Hidden lgDown>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          padding: '2px 0',
          boxShadow: 1,
          borderBottom: '1px solid #e8e8e8',
          filter:
            Array.isArray(layoutClient) &&
            layoutClient.length > 0 &&
            layoutClient.filter(
              (x) =>
                x.sectionName === ElementSection.GRAYBACKGROUND && x.isDisplay,
            ).length > 0
              ? 'grayscale(100%)'
              : 'unset',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: {lg: 1420, xl: 1420, sx: 200, sm: 300},
            mx: 'auto',
            px: {lg: 2, xs: 5},
            maxHeight: '20vh',
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              pl: {lg: 15},
              fontWeight: Fonts.BOLD,
              '& span': {
                color: (theme) => theme.palette.primary.main,
                textDecoration: 'none',
              },
            }}
          >
            <AppWeather />
          </Box>
          {/* {Array.isArray(activities) && activities.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Iconify
                icon='icon-park-solid:trend'
                width={35}
                height={35}
                sx={{color: 'red'}}
              />
              <AppFastMarquee speed={30} pauseOnHover gradientWidth={30}>
                {activities &&
                  activities.map((rs) => {
                    return (
                      <Box
                        key={rs.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyItems: 'flex-start',
                          px: 2,
                        }}
                      >
                        <Typography
                          color='text.secondary'
                          sx={{
                            fontSize: 9,
                            fontWeight: Fonts.BOLD,
                            pl: 2,
                          }}
                        >
                          {rs.ngayHoatDong}
                        </Typography>
                        <Typography
                          color='text.secondary'
                          sx={{
                            fontSize: 12,
                            color: (theme) =>
                              theme.palette.secondary.mauExtension,
                            fontWeight: Fonts.BOLD,
                          }}
                        >
                          &#8226; {rs.tieuDe}
                        </Typography>
                      </Box>
                    );
                  })}
              </AppFastMarquee>
            </Box>
          ) : null} */}

          <Box
            sx={{
              pl: {lg: 15},
              display: 'flex',
            }}
          >
            {isAuthenticated && (
              <UserInfo
                isView={false}
                isHome={true}
                isSticky={false}
                isClient={true}
              />
            )}
            {isAuthenticated &&
              listBlog &&
              listBlog.find((x) => x.tenDanhMuc === 'PHẢN ÁNH KIẾN NGHỊ') && (
                <AppNotificationsReflect />
              )}
            {/* {isAuthenticated && } */}
          </Box>

          {!isAuthenticated && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                minWidth: '235px',
              }}
            >
              <Box
                sx={{
                  color: 'grey.800',
                }}
              >
                <Box
                  component='span'
                  sx={{
                    '& a': {
                      color: (theme) => theme.palette.secondary.mauExtension,
                      textDecoration: 'none',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Link href='/dang-ky'>
                    <a>
                      <Box
                        component='span'
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 14,
                          fontWeight: Fonts.MEDIUM,
                          color: (theme) =>
                            theme.palette.secondary.mauExtension,
                        }}
                      >
                        Đăng ký
                        <IconButton
                          aria-label='close'
                          sx={{
                            color: (theme) =>
                              theme.palette.secondary.mauExtension,
                            '&:hover': {
                              background: 'none',
                            },
                          }}
                          disableRipple
                          disableElevation
                        >
                          <Iconify
                            icon='bx:user-plus'
                            sx={{
                              fontSize: 20,
                            }}
                          />
                        </IconButton>
                      </Box>
                    </a>
                  </Link>
                </Box>
              </Box>
              <Box
                sx={{
                  color: 'grey.500',
                }}
              >
                |
              </Box>
              <Box
                sx={{
                  mx: 2,
                }}
              >
                <Box
                  component='span'
                  sx={{
                    '& a': {
                      color: (theme) => theme.palette.primary.main,
                      textDecoration: 'none',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Link href='/dang-nhap'>
                    <a>
                      <Box
                        component='span'
                        sx={{
                          fontSize: 14,
                          alignItems: 'center',
                          display: 'flex',
                          fontWeight: Fonts.MEDIUM,
                          color: (theme) =>
                            theme.palette.secondary.mauExtension,
                        }}
                      >
                        Đăng nhập
                        <IconButton
                          aria-label='close'
                          sx={{
                            color: (theme) =>
                              theme.palette.secondary.mauExtension,
                            '&:hover': {
                              background: 'none',
                            },
                          }}
                          disableRipple
                        >
                          <Iconify
                            icon='bx:user'
                            sx={{
                              fontSize: 20,
                            }}
                          />
                        </IconButton>
                      </Box>
                    </a>
                  </Link>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Hidden>
  );
};

export default NotificationBar;
