import React from 'react';
import Box from '@mui/material/Box';
import {Fonts} from '../../../../../shared/constants/AppEnums';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Link from 'next/link';

const AppTitle = () => {
  return (
    <>
      <Box
        sx={{
          py: 3,
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'center',
        }}
      >
        <Box sx={{py: 0.5}}>
          <Link href={'/admin'}>
            <a style={{all: 'unset'}}>
              <Typography
                component='h3'
                variant='h3'
                sx={{
                  fontWeight: Fonts.BOLD,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 15,
                  textTransform: 'uppercase',
                  color: (theme) => theme.palette.secondary.mauExtension,
                }}
              >
                Quản trị hệ thống
              </Typography>
            </a>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default AppTitle;
