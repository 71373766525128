import React from 'react';
import List from '@mui/material/List';

import NavVerticalGroup from './VerticalNavGroup';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import {useRouter} from 'next/router';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import {useSelector} from 'react-redux';

const VerticalNavClient = () => {
  const router = useRouter();
  const dataRouteHome = useSelector(
    ({categoryApp}) => categoryApp.treeCategories.data,
  );
  const categoryHome = {
    id: 1,
    title: 'TRANG CHỦ',
    messageId: 'sidebar',
    type: 'item',
    url: '/',
    children: null,
    icon: <HomeTwoToneIcon fontSize='medium' />,
  };

  if (!dataRouteHome) return null;
  return (
    <List
      sx={{
        position: 'relative',
        padding: 0,
      }}
      component='div'
    >
      <NavVerticalGroup item={categoryHome} level={0} router={router} />
      {dataRouteHome.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && (
            <NavVerticalGroup item={item} level={0} router={router} />
          )}

          {item.type === 'collapse' && (
            <VerticalCollapse item={item} level={0} router={router} />
          )}

          {item.type === 'item' && (
            <VerticalItem item={item} level={0} router={router} />
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default VerticalNavClient;
