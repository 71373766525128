import AppAnimate from '@common/core/AppAnimate';
import AppGridContainer from '@common/core/AppGridContainer';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from 'next/link';
import React from 'react';
import ExtensionContainerStatics from './ExtensionContainer';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {ElementSection} from 'shared/constants/AppEnums';

export default function AppExtension() {
  const [source, setSource] = useState();
  const [dataExtension, setDataExtension] = useState([]);

  const treeCategories = useSelector(
    ({categoryApp}) => categoryApp.treeCategories.data,
  );
  const layoutClient = useSelector(
    ({layoutClientApp}) => layoutClientApp.layoutClientList,
  );
  const listBlog = useSelector(
    ({lawDisseminationApp}) => lawDisseminationApp.listBlog,
  );

  useEffect(() => {
    const source = treeCategories.find(({source}) => source === 2)?.originSlug;
    setSource(source);
  }, [treeCategories]);

  useEffect(() => {
    const tempData = [];
    if (Array.isArray(listBlog) && !!listBlog.length) {
      for (let i = 0; i < listBlog.length; i++) {
        if (listBlog[i].tenDanhMuc.toUpperCase() === 'GIỚI THIỆU') {
          tempData.push({
            ...listBlog[i],
            name: 'Giới thiệu',
            href: '/gioi-thieu',
            iconPath: '/assets/images/home-pages/categoryContact.png',
            color: '#c62e14',
          });
        } else if (
          listBlog[i].tenDanhMuc.toUpperCase() === 'PHỔ BIẾN PHÁP LUẬT'
        ) {
          tempData.push({
            ...listBlog[i],
            name: 'Phổ biến pháp luật',
            href: '/pho-bien-phap-luat',
            iconPath: '/assets/images/home-pages/categoryBook.png',
            color: '#6c6c6c',
          });
        } else if (listBlog[i].tenDanhMuc.toUpperCase() === 'DỊCH VỤ CÔNG') {
          tempData.push({
            ...listBlog[i],
            name: 'Dịch vụ công',
            href: `/${source}`,
            iconPath: '/assets/images/home-pages/categoryService.png',
            color: '#e89319',
          });
        } else if (
          listBlog[i].tenDanhMuc.toUpperCase() === 'HỎI ĐÁP TRỰC TUYẾN'
        ) {
          tempData.push({
            ...listBlog[i],
            name: 'Hỏi đáp trực tuyến',
            href: '/hoi-dap',
            iconPath: '/assets/images/home-pages/categoryQuestion.png',
            color: '#0a903b',
          });
        } else if (
          listBlog[i].tenDanhMuc.toUpperCase() === 'PHẢN ÁNH KIẾN NGHỊ'
        ) {
          tempData.push({
            ...listBlog[i],
            name: 'Phản ánh kiến nghị',
            href: '/phan-anh-kien-nghi',
            iconPath: '/assets/images/home-pages/categoryComplain.png',
            color: '#0a903b',
          });
        } else if (
          listBlog[i].tenDanhMuc.toUpperCase() === 'THƯ VIỆN PHÁP LUẬT'
        ) {
          tempData.push({
            ...listBlog[i],
            name: 'Thư viện pháp luật',
            href: `/thu-vien-phap-luat`,
            iconPath: '/assets/images/home-pages/categoryLibrary.png',
            color: '#0a903b',
          });
        }
      }
    }
    setDataExtension(tempData);
  }, [listBlog, source]);
  // const dataExtension = [
  //   {
  //     id: 1,
  //     name: 'Giới thiệu',
  //     href: '/gioi-thieu',
  //     iconPath: '/assets/images/home-pages/categoryContact.png',
  //     color: '#c62e14',
  //   },
  //   {
  //     id: 2,
  //     name: 'Phổ biến pháp luật',
  //     href: '/pho-bien-phap-luat',
  //     iconPath: '/assets/images/home-pages/categoryBook.png',
  //     color: '#6c6c6c',
  //   },
  //   {
  //     id: 3,
  //     name: 'Dịch vụ công',
  //     href: `/${source}`,
  //     iconPath: '/assets/images/home-pages/categoryService.png',
  //     color: '#e89319',
  //   },
  //   {
  //     id: 4,
  //     name: 'Phản ánh kiến nghị',
  //     href: '/phan-anh-kien-nghi',
  //     iconPath: '/assets/images/home-pages/categoryComplain.png',
  //     color: '#0a903b',
  //   },
  //   {
  //     id: 4,
  //     name: 'Hỏi đáp trực tuyến',
  //     href: '/hoi-dap',
  //     iconPath: '/assets/images/home-pages/categoryQuestion.png',
  //     color: '#0a903b',
  //   },
  // ];

  return (
    <AppAnimate animation='transition.slideUpIn' delay={200}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.secondary.mauExtension,
          filter:
            Array.isArray(layoutClient) &&
            layoutClient.length > 0 &&
            layoutClient.filter(
              (x) =>
                x.sectionName === ElementSection.GRAYBACKGROUND && x.isDisplay,
            ).length > 0
              ? 'grayscale(100%)'
              : 'none',
        }}
      >
        <Box sx={{maxWidth: {lg: 1140}, mx: 'auto', pl: {md: 1}}}>
          <AppGridContainer
            spacing={0.5}
            sx={{
              py: 0,
              justifyContent: 'center',
              boxShadow: 0,
              mt: 0,
            }}
          >
            {dataExtension.map((data, index) => (
              <Grid
                item
                xs={12 / dataExtension.length}
                sm={12 / dataExtension.length}
                lg={12 / dataExtension.length}
                sx={{
                  cursor: 'pointer',
                }}
                key={index}
              >
                <Link href={data.href}>
                  <a
                    style={{
                      all: 'unset',
                    }}
                  >
                    <ExtensionContainerStatics
                      data={data}
                      index={index}
                      number={dataExtension.length}
                    />
                  </a>
                </Link>
              </Grid>
            ))}
          </AppGridContainer>
        </Box>
      </Box>
    </AppAnimate>
  );
}
