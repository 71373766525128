export const checkPermission = (routeRole, userRole) => {
  if (!routeRole || !routeRole) {
    return true;
  }

  if (userRole && Array.isArray(userRole) && !Array.isArray(routeRole)) {
    return userRole.indexOf(routeRole) >= 0;
  }
  if (routeRole.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeRole)) {
    return routeRole.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeRole.indexOf(userRole) >= 0;
};

export const checkPathPermission = (
  asPath,
  userPermission,
  permittedPermission,
) => {
  if (!asPath || !userPermission) {
    return true;
  }

  if (
    userPermission &&
    Array.isArray(userPermission) &&
    !!Array.isArray(userPermission)
  ) {
    return userPermission.some(
      (p) =>
        asPath.includes(p.url) &&
        p.quyen.split(',').some((r) => r.includes(permittedPermission ?? 'R')),
    );
  }

  if (userPermission.length === 0) {
    return !userPermission || userPermission.length === 0;
  }
};

export const checkChildrenPermission = (
  children,
  userPermission,
  permittedPermission,
) => {
  if (!children || !userPermission) {
    return true;
  }
  if (
    userPermission &&
    Array.isArray(userPermission) &&
    !!Array.isArray(userPermission) &&
    children &&
    Array.isArray(children) &&
    !!Array.isArray(children)
  ) {
    let flag = false;

    children.forEach((element) => {
      if (
        userPermission.some(
          (p) =>
            element.url.includes(p.url) &&
            p.quyen
              .split(',')
              .some((r) => r.includes(permittedPermission ?? 'R')),
        )
      ) {
        flag = true;
      }
    });
    return flag;
  }

  if (userPermission.length === 0) {
    return !userPermission || userPermission.length === 0;
  }
};

export const checkPermissionClient = (
  userPermission,
  permittedPermission,
  url,
) => {
  if (!userPermission || !url || !permittedPermission) return false;

  if (
    userPermission &&
    Array.isArray(userPermission) &&
    !!Array.isArray(userPermission)
  ) {
    return userPermission.some(
      (p) =>
        p.quyen.split(',').some((r) => r.includes(permittedPermission)) &&
        p.url.includes(url),
    );
  }

  return false;
};
