import jwtAxios from '@common/services/auth/jwt-auth';
import {
  appIntl,
  buildQueryString,
  parseParams,
} from '@common/utility/helper/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_QUESTION_CLIENT,
  GET_QUESTION_LIST,
  GET_QUESTION_CLIENT_FAIL,
  QUESTION_GET_FIELD,
  GET_QUESTION_FIELD_CLIENT_FAIL,
  SEND_QUESTION,
  SENT_QUESTION,
  GET_MOST_VIEW,
  GET_CLIENT_MOST_VIEW_FIELD,
  GET_QUESTION_DETAIL_CLIENT,
  GET_QUESTION_DETAIL,
  GET_QUESTION_DETAIL_CLIENT_FAIL,
  QUESTION_ANSWERED,
  QUESTION_ANSWER,
  QUESTION_GET_CAP_HC_BY_FIELD,
  QUESTION_GET_CAP_HC_CLIENT_FAIL,
  GET_QUESTION_NEED_PROCESS_FAIL,
  GET_QUESTION_NEED_PROCESS,
  GET_QUESTION_NEED_PROCESS_CLIENT,
} from 'shared/constants/ActionTypes';
import {
  URL_QUESTION_GET_LIST,
  URL_QUESTION_FIELD,
  URL_QUESTION_CREATE,
  URL_QUESTION_MOST_VIEW,
  URL_QUESTION_DETAIL,
  URL_QUESTION_ANSWER,
  URL_QUESTION_CAP_HC_BY_FIELD,
  URL_QUESTION_NEED_PROCESS,
} from 'shared/constants/api-url/question';
import {API_CAT_TTHC} from 'shared/constants/UrlApis';

jwtAxios.defaults.baseURL = `${API_CAT_TTHC}`;

export const onGetQuestionList = (dataFilter) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_TTHC}`;
  return (dispatch) => {
    dispatch({type: GET_QUESTION_CLIENT});
    const url = `${URL_QUESTION_GET_LIST}?${buildQueryString(
      parseParams({
        ...dataFilter,
      }),
    )}`;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_QUESTION_LIST, payload: data.data});
        } else {
          dispatch({
            type: GET_QUESTION_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_QUESTION_CLIENT_FAIL,
        });
      });
  };
};
export const onGetListQuestionField = () => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_TTHC}`;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(URL_QUESTION_FIELD)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: QUESTION_GET_FIELD, payload: data.data});
        } else {
          dispatch({
            type: GET_QUESTION_FIELD_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_QUESTION_FIELD_CLIENT_FAIL,
        });
      });
  };
};

export const onGetListCapHCByField = (value) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_TTHC}`;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(`${URL_QUESTION_CAP_HC_BY_FIELD}?LinhVucId=${value}`)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: QUESTION_GET_CAP_HC_BY_FIELD, payload: data.data});
        } else {
          dispatch({
            type: QUESTION_GET_CAP_HC_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: QUESTION_GET_CAP_HC_CLIENT_FAIL,
        });
      });
  };
};

export const onCreateQuestion = (field, dataFilter, router) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_TTHC}`;
  return (dispatch) => {
    dispatch({type: SEND_QUESTION});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_QUESTION_CREATE, field)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.questionCreated'],
          });
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SENT_QUESTION});
          if (!field.continueCreate) {
            router.push('/hoi-dap');
            dispatch(onGetQuestionList(dataFilter));
          }
        } else {
          dispatch({type: SENT_QUESTION});
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      })
      .catch((error) => {
        dispatch({type: SENT_QUESTION});
        console.error(error.message);
      });
  };
};

export const onGetMostViewQuestion = () => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_TTHC}`;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(URL_QUESTION_MOST_VIEW)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_MOST_VIEW, payload: data.data});
        } else {
          dispatch({
            type: GET_CLIENT_MOST_VIEW_FIELD,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_CLIENT_MOST_VIEW_FIELD,
        });
      });
  };
};

export const onGetDetailQuestion = (slug, router) => {
  jwtAxios.defaults.baseURL = `${API_CAT_TTHC}`;
  const url = `${URL_QUESTION_DETAIL}?Slug=${slug}`;
  return (dispatch) => {
    dispatch({type: GET_QUESTION_DETAIL_CLIENT});
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_QUESTION_DETAIL,
            payload: data.data,
          });
        } else if (data.data.statusCode === 404) {
          router.push('/404');
        } else {
          dispatch({
            type: GET_QUESTION_DETAIL_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_QUESTION_DETAIL_CLIENT_FAIL,
        });
        // if (error.response.status === 404) {
        //   router.push('/404');
        // }
      });
  };
};

export const onAnswerQuestion = (field, handleCloseTraLoi, slug, router) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_TTHC}`;
  return (dispatch) => {
    dispatch({type: QUESTION_ANSWER});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_QUESTION_ANSWER, field)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          handleCloseTraLoi();
          dispatch(onGetDetailQuestion(slug, router));
          dispatch({type: QUESTION_ANSWERED});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.questionAnswered'],
          });
        } else {
          dispatch({type: QUESTION_ANSWERED});
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({type: QUESTION_ANSWERED});
      });
  };
};

export const onGetQuestionNeedProcess = (dataFilter) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_TTHC}`;
  return (dispatch) => {
    dispatch({type: GET_QUESTION_NEED_PROCESS_CLIENT});
    const url = `${URL_QUESTION_NEED_PROCESS}?${buildQueryString(
      parseParams({
        ...dataFilter,
      }),
    )}`;
    jwtAxios
      .post(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_QUESTION_NEED_PROCESS, payload: data.data});
        } else {
          dispatch({
            type: GET_QUESTION_NEED_PROCESS_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_QUESTION_NEED_PROCESS_FAIL,
        });
      });
  };
};
