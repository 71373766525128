import React from 'react';
import {toggleNavCollapsedHide} from '../../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AppScrollbar from '../../../AppScrollbar';
import MainSidebar from '../../components/MainSidebar';
import Drawer from '@mui/material/Drawer';
import StandardSidebarWrapper from './StandardSidebarWrapper';
import UserInfo from '../../components/UserInfo';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import VerticalNavClient from '../../components/VerticalNavClient';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Iconify from '@common/core/Iconify';
import {Fonts} from 'shared/constants/AppEnums';
import Link from 'next/link';
import {ElementSection} from 'shared/constants/AppEnums';

const AppSidebar = (props) => {
  const dispatch = useDispatch();
  const navCollapsed = useSelector(({settings}) => settings.navCollapsed);
  const layoutClient = useSelector(
    ({layoutClientApp}) => layoutClientApp.layoutClientList,
  );
  const {sidebarTextColor} = useSidebarContext();

  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsedHide());
  };

  const isAuthenticated = useSelector(
    ({authentication}) => authentication.isAuthenticated,
  );

  return (
    <>
      <Drawer
        anchor={props.position}
        open={navCollapsed}
        onClose={() => handleToggleDrawer()}
        classes={{
          root: clsx(props.variant),
          paper: clsx(props.variant),
        }}
        style={{
          position: 'absolute',
          filter:
            Array.isArray(layoutClient) &&
            layoutClient.length > 0 &&
            layoutClient.filter(
              (x) =>
                x.sectionName === ElementSection.GRAYBACKGROUND && x.isDisplay,
            ).length > 0
              ? 'grayscale(100%)'
              : 'unset',
        }}
      >
        <StandardSidebarWrapper className='standard-sidebar'>
          <MainSidebar>
            <Box
              sx={{
                py: 3,
                px: 3,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                background: (theme) => theme.palette.secondary.mauExtension,
              }}
              className='user-info-view'
            >
              <Box
                sx={{
                  width: {xs: 'calc(100% - 62px)', xl: 'calc(100% - 72px)'},
                  ml: 4,
                }}
                className='user-info'
              >
                <UserInfo
                  isView={false}
                  isHome={true}
                  isSticky={true}
                  isClient={true}
                />
                {!isAuthenticated && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{mx: 2}}>
                      <Box
                        component='span'
                        sx={{
                          '& a': {
                            color: (theme) => theme.palette.primary.main,
                            textDecoration: 'none',
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <Link href='/dang-ky'>
                          <a>
                            <Box
                              component='span'
                              sx={{
                                fontSize: 14,
                                alignItems: 'center',
                                display: 'flex',
                                fontWeight: Fonts.MEDIUM,
                                color: (theme) => theme.palette.grey['50'],
                              }}
                            >
                              Đăng ký
                            </Box>
                          </a>
                        </Link>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        color: 'grey.500',
                      }}
                    >
                      |
                    </Box>
                    <Box
                      sx={{
                        mx: 2,
                      }}
                    >
                      <Box
                        component='span'
                        sx={{
                          '& a': {
                            color: (theme) => theme.palette.primary.main,
                            textDecoration: 'none',
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <Link href='/dang-nhap'>
                          <a>
                            <Box
                              component='span'
                              sx={{
                                fontSize: 14,
                                alignItems: 'center',
                                display: 'flex',
                                fontWeight: Fonts.MEDIUM,
                                color: (theme) => theme.palette.grey['50'],
                              }}
                            >
                              Đăng nhập
                            </Box>
                          </a>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <AppScrollbar
              sx={{
                py: 2,
                height: 'calc(100vh - 70px) !important',
              }}
              scrollToTop={false}
            >
              <VerticalNavClient />
            </AppScrollbar>
          </MainSidebar>
        </StandardSidebarWrapper>
      </Drawer>
    </>
  );
};
export default AppSidebar;

AppSidebar.defaultProps = {
  variant: '',
  position: 'left',
};

AppSidebar.propTypes = {
  position: PropTypes.string,
  variant: PropTypes.string,
};
