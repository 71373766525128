import React from 'react';
import AppCard from '@common/core/AppCard';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Image from '@common/core/AppMuiImage';
import {Fonts} from 'shared/constants/AppEnums';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/styles/useTheme';

const ExtensionContainerStatics = ({data, index, number}) => {
  const theme = useTheme();
  const {icon, name, id, iconPath} = data;
  const isHome = window.location.pathname === '/' ? true : false;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <AppCard
      sxStyle={{
        backgroundColor: (theme) => theme.palette.secondary.mauExtension,
        boxShadow: 'unset',
        borderRadius: 'unset',
        height: '100%',
        borderLeft: `${index === 0 ? '' : '2px solid #e8e5dd'}`,
        alignItems: 'center',
      }}
      contentStyle={{p: {xs: isHome ? 1.5 : 1, lg: 5}}}
      className='card-hover'
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: {lg: 'row', xs: 'column'},
          justifyContent: 'center',
          justifyItems: 'center',
        }}
      >
        {!isMobile && !icon ? (
          <Box sx={{px: 2}}>
            <Image
              src={iconPath}
              alt='icon'
              fit={'fill'}
              height={isMobile ? 20 : 25}
              width={isMobile ? 20 : 25}
            />
          </Box>
        ) : (
          <Box sx={{px: 2}}>{icon}</Box>
        )}

        <Box>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'center',
              whiteSpace: isMobile ? 'normal' : 'nowrap',
              width: '100%',
              color: (theme) =>
                !isMobile
                  ? theme.palette.grey['50']
                  : theme.palette.secondary.yellowCongAn,
              fontWeight: Fonts.MEDIUM,
              fontSize: {xs: 10, xl: 13, lg: 13, sm: 11},
              mt: isMobile ? 3 : 0,
            }}
          >
            {name.toUpperCase()}
          </Typography>
        </Box>
      </Box>
    </AppCard>
  );
};

export default ExtensionContainerStatics;

ExtensionContainerStatics.propTypes = {
  data: PropTypes.object,
  index: PropTypes.any,
  number: PropTypes.any,
};
