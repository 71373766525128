export const URL_QUESTION_GET_LIST = 'cau-hoi/list';
export const URL_QUESTION_DETAIL = 'cau-hoi/get';
export const URL_QUESTION_MOST_VIEW = 'cau-hoi/xem-nhieu-nhat';
export const URL_QUESTION_CREATE = 'cau-hoi/gui';
export const URL_QUESTION_APPROVE = 'cau-hoi/duyet';
export const URL_QUESTION_ANSWER = 'tra-loi/gui';

export const URL_QUESTION_FIELD = 'common/dropdown-linh-vuc';
export const URL_QUESTION_CAP_HC_BY_FIELD = 'cau-hoi/cap-hanh-chinh';
export const URL_QUESTION_NEED_PROCESS = 'cau-hoi/xu-ly';
