/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Fragment} from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Hidden from '@mui/material/Hidden';
import {toggleNavCollapsed} from 'redux/actions';
import Box from '@mui/material/Box';
import Carousel from 'react-material-ui-carousel';
import BannerSkeleton from '@common/core/AppSkeleton/BannerSkeleton';
import AppCardMedia from '@common/core/AppCard/AppCardMedia';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Image from '@common/core/LazyLoadImage';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/styles/useTheme';
import CarouselBasic from '@common/core/AppCarousel/CarouselBasic';
import Typography from '@mui/material/Typography';
import AppNotificationsReflect from '../../../AppNotificationsReflect';
import {Fonts} from 'shared/constants/AppEnums';
import {ElementSection} from 'shared/constants/AppEnums';

const useStyles = makeStyles(() => ({
  carousel: {
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
}));

const AppBanner = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const dataBanner = useSelector(
    ({bannerApp}) => bannerApp.bannerListClient.top,
  );
  const layoutClient = useSelector(
    ({layoutClientApp}) => layoutClientApp.layoutClientList,
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLoadBannerClient = useSelector(
    ({bannerApp}) => bannerApp.isLoadBannerClient,
  );
  const isAuthenticated = useSelector(
    ({authentication}) => authentication.isAuthenticated,
  );
  const listBlog = useSelector(
    ({lawDisseminationApp}) => lawDisseminationApp.listBlog,
  );
  if (!dataBanner) return null;

  return (
    <Fragment>
      <Hidden lgUp>
        <Toolbar
          sx={{
            boxSizing: 'border-box',
            minHeight: {xs: 56, sm: 70},
            px: {xs: 0},
            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            background: (theme) => theme.palette.secondary.mauExtension,
            filter:
              Array.isArray(layoutClient) &&
              layoutClient.length > 0 &&
              layoutClient.filter(
                (x) =>
                  x.sectionName === ElementSection.GRAYBACKGROUND &&
                  x.isDisplay,
              ).length > 0
                ? 'grayscale(100%)'
                : 'unset',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: {lg: 1140, xl: 1420},
              mx: 'auto',
              px: 5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              sx={{
                marginRight: (theme) => theme.spacing(1),
                color: 'text.secondary',
              }}
              edge='start'
              className='menu-btn'
              color='inherit'
              aria-label='open drawer'
              onClick={() => dispatch(toggleNavCollapsed())}
              size='large'
            >
              <MenuIcon
                sx={{
                  width: 35,
                  height: 35,
                  color: 'white',
                }}
              />
            </IconButton>
            <Typography
              variant='h6'
              sx={{
                color: (theme) => theme.palette.secondary.yellowCongAn,
                fontWeight: Fonts.BOLD,
                fontSize: {xs: 13},
              }}
            >
              CÔNG AN TỈNH THỪA THIÊN HUẾ
            </Typography>
            <Box sx={{ml: isMobile ? 'auto' : 'none'}}>
              <Image
                src='assets/images/quocHuy.png'
                sx={{height: 32, width: 40, marginLeft: 4, right: 0}}
              />
            </Box>
            {isAuthenticated &&
              listBlog &&
              listBlog.find((x) => x.tenDanhMuc === 'PHẢN ÁNH KIẾN NGHỊ') && (
                <>
                  <Box sx={{mx: 2}} />
                  <AppNotificationsReflect sx={{height: 50, width: 60}} />
                </>
              )}
          </Box>
        </Toolbar>
      </Hidden>
      <Hidden lgDown>
        {isLoadBannerClient && dataBanner.length === 0 ? (
          <BannerSkeleton
            sx={{
              filter:
                Array.isArray(layoutClient) &&
                layoutClient.length > 0 &&
                layoutClient.filter(
                  (x) =>
                    x.sectionName === ElementSection.GRAYBACKGROUND &&
                    x.isDisplay,
                ).length > 0
                  ? 'grayscale(100%)'
                  : 'unset',
            }}
          />
        ) : (
          <Box
            sx={{
              maxHeight: {xl: 250},
              // backgroundColor: (theme) => theme.palette.background.default,
              backgroundImage: (theme) =>
                `linear-gradient(to top, ${'#790303'} 2%,#DB080D 45%)`,
              filter:
                Array.isArray(layoutClient) &&
                layoutClient.length > 0 &&
                layoutClient.filter(
                  (x) =>
                    x.sectionName === ElementSection.GRAYBACKGROUND &&
                    x.isDisplay,
                ).length > 0
                  ? 'grayscale(100%)'
                  : 'unset',
            }}
          >
            {dataBanner.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  maxHeight: {xl: 230},
                  py: 1,
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    zIndex: 9,
                    top: 0,
                    width: {lg: 1130, xl: 1130},
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    pl: 2,
                  }}
                >
                  <Image src='/assets/images/logo_cattthue.png' />
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    mx: 'auto',
                    maxWidth: {xl: 1134, lg: 1134},
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: {
                        xl: 880,
                        md: 880,
                      },
                      ml: {xl: 62, lg: 62},
                    }}
                  >
                    <CarouselBasic _carousels={dataBanner} />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Hidden>
    </Fragment>
  );
};

export default AppBanner;

AppBanner.defaultProps = {
  cityData: [],
};

AppBanner.propTypes = {
  cityData: PropTypes.array,
};
