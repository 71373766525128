import jwtAxios from '@common/services/auth/jwt-auth';
import {formErrorBE} from '@common/utility/helper/FormHelper';
import serialize from '@common/utility/helper/ObjectToFormData';
import {
  appIntl,
  buildQueryString,
  parseParams,
} from '@common/utility/helper/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CUSTOMER_LIST,
  GET_DATA_FOOTER,
  GET_DATA_FOOTER_FAIL,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import {
  URL_CUSTOMER_UPDATE,
  URL_CUSTOMER_GET_LIST,
  URL_GET_DATA_FOOTER,
} from 'shared/constants/api-url/customer';

import {API_CAT_DANHMUC} from 'shared/constants/UrlApis';
import {API_CAT} from 'shared/constants/UrlApis';

export const onGetCustomerList = () => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(URL_CUSTOMER_GET_LIST)
      .then((data) => {
        if (data.data.statusCode === 200) {
          let response = data.data;
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CUSTOMER_LIST, payload: response});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const onUpdateSelectedCustomer = (customer, onToggle) => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  let formData = serialize(customer);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_CUSTOMER_UPDATE, formData)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(onGetCustomerList());
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.customerUpdated'],
          });
          onToggle(false);
        } else {
          formErrorBE(data, dispatch, onToggle);
        }
      })
      .catch((error) => {
        formErrorBE(error, dispatch, onToggle);
      });
  };
};
export const onGetDataFooter = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    const url = `${URL_GET_DATA_FOOTER}`;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          let response = data.data;
          dispatch({type: GET_DATA_FOOTER, payload: response});
        } else {
          dispatch({
            type: GET_DATA_FOOTER_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_DATA_FOOTER_FAIL,
        });
      });
  };
};
