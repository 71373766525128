import React, {useMemo} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import VerticalCollapse from '../VerticalCollapse';
import VerticalItem from '../VerticalItem';
import {checkPermission} from '../../../../../utility/helper/RouteHelper';
import {useAuthUser} from '../../../../../utility/AuthHooks';
import {useSidebarContext} from '../../../../../utility/AppContextProvider/SidebarContextProvider';
import VerticalNavGroupItem from './VerticalNavGroupItem';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {Fragment} from 'react';
import Link from 'next/link';

const VerticalNavGroup = ({item, router, level}) => {
  const {sidebarTextColor} = useSidebarContext();
  const {user} = useAuthUser();
  // const hasPermission = useMemo(
  //   () => checkPermission(item.permittedRole, user.role),
  //   [item.permittedRole, user.role],
  // );

  // if (!hasPermission) {
  //   return null;
  // }
  return (
    <Fragment>
      <Link href={item.url}>
        <VerticalNavGroupItem
          level={level}
          sidebarTextColor={sidebarTextColor}
          component='div'
          className={clsx('nav-item nav-item-header')}
        >
          {item.icon && (
            <Icon
              color='action'
              sx={{color: (theme) => theme.palette.secondary.mauExtension}}
            >
              {item.icon}
            </Icon>
          )}
          <Box>{item.title}</Box>
          {item.children && item.children.length > 0 && (
            <IconButton
              sx={{
                ml: 1,
              }}
              disableRipple
            >
              <Icon
                sx={{
                  fontSize: 30,
                }}
                className='arrow-icon'
              >
                keyboard_arrow_down
              </Icon>
            </IconButton>
          )}
        </VerticalNavGroupItem>
      </Link>
      {item.children && (
        <>
          {item.children.map((item) => (
            <React.Fragment key={item.id}>
              {item.type === 'group' && (
                <NavVerticalGroup item={item} level={level} router={router} />
              )}

              {item.type === 'collapse' && (
                <VerticalCollapse item={item} level={level} router={router} />
              )}

              {item.type === 'item' && (
                <VerticalItem item={item} level={level} router={router} />
              )}
            </React.Fragment>
          ))}
        </>
      )}

      <Divider />
    </Fragment>
  );
};

VerticalNavGroup.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    permittedRole: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    messageId: PropTypes.string,
    children: PropTypes.array,
    url: PropTypes.string,
  }),
  level: PropTypes.number,
  router: PropTypes.object,
};

VerticalNavGroup.defaultProps = {};

const NavVerticalGroup = VerticalNavGroup;

export default NavVerticalGroup;
