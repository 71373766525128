import React, {useState} from 'react';
import {useRouter} from 'next/router';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Manager, Popper, Reference} from 'react-popper';
import * as ReactDOM from 'react-dom';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalItem from './HorizontalItem';
import Box from '@mui/material/Box';
import IntlMessages from '../../../../utility/IntlMessages';
import {Fonts} from '../../../../../shared/constants/AppEnums';

import Grow from '@mui/material/Grow';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import {useDispatch, useSelector} from 'react-redux';
import Link from 'next/link';

function HorizontalGroup(props) {
  const [opened, setOpened] = useState(false);
  const {pathname, asPath} = useRouter();
  const {item, nestedLevel} = props;
  const dispatch = useDispatch();
  const breadcrumbs = useSelector(({common}) => common.breadcrumbs);

  const handleToggle = (open) => {
    setOpened(open);
  };

  function isUrlInChildren(parent, url) {
    if (!parent.children) {
      return false;
    }

    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].children) {
        if (isUrlInChildren(parent.children[i], url)) {
          return true;
        }
      }

      if (
        parent.children[i].url === url ||
        url.includes(parent.children[i].url)
      ) {
        return true;
      }
    }

    return false;
  }

  const isActiveArticle = () => {
    if (asPath.includes('/bai-viet/')) {
      if (Array.isArray(breadcrumbs) && breadcrumbs.length > 0) {
        const result = breadcrumbs.filter((x) => x.slug === item.originSlug);
        if (result.length > 0) {
          return true;
        }
      }
    }
    return false;
  };
  return (
    <Manager>
      <Reference>
        {({ref}) => (
          <Link href={item.url} as={item.as}>
            <a
              style={{
                all: 'unset',
              }}
            >
              <ListItem
                ref={ref}
                className={clsx(
                  'navItem',
                  (item.url === '/'
                    ? item.url === asPath
                    : asPath.includes(item.url) || isActiveArticle()) &&
                    'active',
                )}
                onMouseEnter={() => handleToggle(true)}
                onMouseLeave={() => handleToggle(false)}
                aria-owns={opened ? 'menu-list-grow' : null}
                aria-haspopup='true'
              >
                <Box
                  key={item.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {item.icon && (
                    <Icon color='action' className='navLinkIcon'>
                      {item.icon}
                    </Icon>
                  )}
                  <ListItemText
                    // primary={<IntlMessages id={item.messageId} />}
                    primary={item.title}
                    primaryTypographyProps={{
                      fontWeight: Fonts.BOLD,
                      fontSize: 12,
                    }}
                  />
                </Box>

                {/* {item.children && item.children.length > 0 && (
                <IconButton
                  sx={{ 
                    ml: 1,
                  }}
                  disableRipple
                >
                  <Icon
                    sx={{
                      fontSize: 18,
                    }}
                    className='arrow-icon'
                  >
                    keyboard_arrow_down
                  </Icon>
                </IconButton>
              )} */}
              </ListItem>
            </a>
          </Link>
        )}
      </Reference>
      {ReactDOM.createPortal(
        <Popper
          placement={nestedLevel === 0 ? 'bottom-start' : 'right'}
          eventsEnabled={opened}
          positionFixed
        >
          {({ref, style, placement}) =>
            opened && (
              <Box
                ref={ref}
                sx={{
                  ...style,
                  boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.2)',
                  zIndex: 1110 + nestedLevel,
                  '& .popperClose': {
                    pointerEvents: 'none',
                  },
                }}
                data-placement={placement}
                className={clsx({
                  popperClose: !opened,
                })}
              >
                <Grow
                  in={opened}
                  id='menu-list-grow'
                  style={{transformOrigin: '0 0 0'}}
                >
                  <Paper
                    onMouseEnter={() => handleToggle(true)}
                    onMouseLeave={() => handleToggle(false)}
                  >
                    {item.children && (
                      <List
                        sx={{
                          px: 0,
                        }}
                      >
                        {item.children.map((item) => (
                          <React.Fragment key={item.id}>
                            {item.type === 'group' && (
                              <HorizontalGroup
                                item={item}
                                nestedLevel={nestedLevel}
                              />
                            )}

                            {item.type === 'collapse' && (
                              <HorizontalCollapse
                                item={item}
                                nestedLevel={nestedLevel}
                              />
                            )}

                            {item.type === 'item' && (
                              <HorizontalItem
                                item={item}
                                nestedLevel={nestedLevel}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </List>
                    )}
                  </Paper>
                </Grow>
              </Box>
            )
          }
        </Popper>,
        document.querySelector('#root'),
      )}
    </Manager>
  );
}

HorizontalGroup.propTypes = {
  item: PropTypes.object,
  nestedLevel: PropTypes.number,
};

HorizontalGroup.defaultProps = {};

export default React.memo(HorizontalGroup);
