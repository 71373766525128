import React, {useEffect} from 'react';
import {useLayoutContext} from '../../../../utility/AppContextProvider/LayoutContextProvider';
import Typography from '@mui/material/Typography';
import FooterWrapper from './FooterWrapper';
import Grid from '@mui/material/Grid';
import FooterNotFixedWrapper from './FooterNotFixedWrapper';
import {useDispatch, useSelector} from 'react-redux';
import {onGetDataFooter} from 'redux/actions/Customer';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import {Fonts} from 'shared/constants/AppEnums';
import Image from '@common/core/AppMuiImage';
import {ElementSection} from 'shared/constants/AppEnums';

const AppFixedFooter = () => {
  const {footer, footerType} = useLayoutContext();
  const dispatch = useDispatch();

  const dataFooter = useSelector(({customerApp}) => customerApp.dataFooter);
  const layoutClient = useSelector(
    ({layoutClientApp}) => layoutClientApp.layoutClientList,
  );
  useEffect(() => {
    dispatch(onGetDataFooter());
  }, [dispatch]);

  return (
    <>
      {footer && footerType === 'fixed' ? (
        <FooterWrapper className='footer fixed-footer'>
          <div className='footerContainer'>
            <Typography>Copy right @3si 2022</Typography>
          </div>
        </FooterWrapper>
      ) : (
        <FooterNotFixedWrapper
          className='footer not-fixed-footer'
          id='footerNotFix'
          sx={{
            filter:
              Array.isArray(layoutClient) &&
              layoutClient.length > 0 &&
              layoutClient.filter(
                (x) =>
                  x.sectionName === ElementSection.GRAYBACKGROUND &&
                  x.isDisplay,
              ).length > 0
                ? 'grayscale(100%)'
                : 'none',
          }}
        >
          <div className='footerContainer'>
            <Grid
              container
              sx={{
                mx: 'auto',
                maxWidth: {lg: 1140, xl: 1140},
                alignItems: {lg: 'center', xs: 'baseline'},
                filter:
                  Array.isArray(layoutClient) &&
                  layoutClient.length > 0 &&
                  layoutClient.filter(
                    (x) =>
                      x.sectionName === ElementSection.GRAYBACKGROUND &&
                      x.isDisplay,
                  ).length > 0
                    ? 'grayscale(100%)'
                    : 'unset',
              }}
            >
              <Hidden lgDown>
                <Grid md={1} xs={0} item>
                  <Box sx={{px: 0}}>
                    {dataFooter && dataFooter.logoFilePath ? (
                      <Image
                        src={dataFooter.logoFilePath}
                        sx={{
                          height: 170,
                          width: 200,
                        }}
                      />
                    ) : null}
                  </Box>
                </Grid>
                <Grid md={6} xs={6}>
                  <Box sx={{px: {lg: 2, xs: 0}, color: 'white'}}>
                    <Typography variant='h5'>{dataFooter.tieuDe}</Typography>
                    <Typography variant='body2'>{dataFooter.donVi}</Typography>
                    <Typography variant='body2'>
                      {dataFooter.giayPhep}
                    </Typography>
                    <Typography variant='body2'>
                      {dataFooter.banQuyen}
                    </Typography>
                    <Typography variant='body2'>{dataFooter.luuY}</Typography>
                  </Box>
                </Grid>
                <Grid
                  md={5}
                  xs={5}
                  display={{lg: 'flex', xs: 'block'}}
                  justifyContent='flex-end'
                  pr={{lg: 7, xs: 1}}
                >
                  <Box sx={{color: 'white'}}>
                    <Typography variant='h5'>
                      Đường dây nóng: {dataFooter.duongDayNong}
                    </Typography>
                    <Typography variant='body2'>
                      Trực ban: {dataFooter.trucBan}
                    </Typography>
                    <Typography variant='body2'>
                      Fax: {dataFooter.fax}
                    </Typography>
                    <Typography variant='body2'>
                      Email: {dataFooter.email}
                    </Typography>
                    <Typography variant='body2'>
                      Phiên bản: {process.env.NEXT_APP_CURRENT_GIT_SHA}
                    </Typography>
                    <a
                      href='https://tinnhiemmang.vn/danh-ba-tin-nhiem/conganthuathienhuegovvn-1698810899'
                      title='Chung nhan Tin Nhiem Mang'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img
                        src='https://tinnhiemmang.vn/handle_cert?id=congan.thuathienhue.gov.vn'
                        width='130px'
                        height='auto'
                        alt='Chung nhan Tin Nhiem Mang'
                        style={{paddingTop: 8}}
                      />
                    </a>
                  </Box>
                  <Hidden lgUp>
                    <Box sx={{px: 2}}>
                      {dataFooter && dataFooter.logoFilePath ? (
                        <div className='grid-1'>
                          <Image
                            src={dataFooter.logoFilePath}
                            height={160}
                            width={160}
                            style={{margin: '0 auto'}}
                          />
                        </div>
                      ) : null}
                    </Box>
                  </Hidden>
                </Grid>
              </Hidden>
              <Hidden lgUp>
                <Grid container spacing={3}>
                  <Grid md={12} xs={12} item sx={{textAlign: 'center'}}></Grid>
                  <Grid md={12} xs={12} item>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography sx={{fontSize: 12, fontWeight: Fonts.BOLD}}>
                        {dataFooter.tieuDe}
                      </Typography>
                      <Typography
                        sx={{fontSize: 10, fontWeight: Fonts.LIGHT}}
                        color='grey.300'
                      >
                        {dataFooter.donVi}
                      </Typography>
                      <Typography
                        sx={{fontSize: 10, fontWeight: Fonts.LIGHT}}
                        color='grey.300'
                      >
                        {dataFooter.giayPhep}
                      </Typography>
                      <Typography
                        sx={{fontSize: 10, fontWeight: Fonts.LIGHT}}
                        color='grey.300'
                      >
                        {dataFooter.banQuyen}
                      </Typography>
                      <Typography
                        sx={{fontSize: 10, fontWeight: Fonts.LIGHT}}
                        color='grey.300'
                      >
                        {dataFooter.luuY}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    md={12}
                    xs={12}
                    item
                    sx={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        px: {lg: 2, xs: 0},
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography sx={{fontSize: 12, fontWeight: Fonts.BOLD}}>
                        Đường dây nóng: {dataFooter.duongDayNong}
                      </Typography>
                      <Typography
                        sx={{fontSize: 10, fontWeight: Fonts.LIGHT}}
                        color='grey.300'
                      >
                        Trực ban: {dataFooter.trucBan}
                      </Typography>
                      <Typography
                        sx={{fontSize: 10, fontWeight: Fonts.LIGHT}}
                        color='grey.300'
                      >
                        Fax: {dataFooter.fax}
                      </Typography>
                      <Typography
                        sx={{fontSize: 10, fontWeight: Fonts.LIGHT}}
                        color='grey.300'
                      >
                        Email: {dataFooter.email}
                      </Typography>
                      <Typography
                        sx={{fontSize: 10, fontWeight: Fonts.LIGHT}}
                        color='grey.300'
                      >
                        Phiên bản: {process.env.NEXT_APP_CURRENT_GIT_SHA}
                      </Typography>
                      <a
                        href='https://tinnhiemmang.vn/danh-ba-tin-nhiem/conganthuathienhuegovvn-1698810899'
                        title='Chung nhan Tin Nhiem Mang'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src='https://tinnhiemmang.vn/handle_cert?id=congan.thuathienhue.gov.vn'
                          width='130px'
                          height='auto'
                          alt='Chung nhan Tin Nhiem Mang'
                          style={{paddingTop: 8}}
                        />
                      </a>
                    </Box>
                    <Box sx={{mx: 'auto'}}>
                      {dataFooter && dataFooter.logoFilePath ? (
                        <Image
                          src={dataFooter.logoFilePath}
                          width={100}
                          height={100}
                          fit='contain'
                        />
                      ) : null}
                    </Box>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
            <div></div>
            {/* <Typography>Copy right @3si 2022</Typography> */}
          </div>
        </FooterNotFixedWrapper>
      )}
    </>
  );
};

export default AppFixedFooter;
