import React from 'react';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import Image from '@common/core/LazyLoadImage';

function AppBannerBottom() {
  const dataBanner = useSelector(
    ({bannerApp}) => bannerApp.bannerListClient.bottom,
  );

  if (!dataBanner || !(Array.isArray(dataBanner) && dataBanner.length > 0))
    return null;
  return (
    <Box
      sx={{
        py: 0,
        borderRadius: 1,
        pb: 1,
        px: 1,
      }}
    >
      <Image
        src={dataBanner[0]}
        sx={{
          height: 140,
          objectFit: 'cover',
        }}
      />
    </Box>
  );
}

AppBannerBottom.propTypes = {};

export default AppBannerBottom;
