import {BiAlignLeft} from 'react-icons/bi';
import {
  MdOutlineDns,
  MdOutlineManageAccounts,
  MdOutlineSettingsApplications,
  MdOutlineHistoryEdu,
  MdMoveToInbox,
  MdOutlineArticle,
  MdCreate,
  MdApproval,
  MdLibraryBooks,
  MdStickyNote2,
  MdWork,
  MdBarChart,
  MdFeed,
  MdAssignmentTurnedIn,
  MdHourglassBottom,
  MdPermMedia,
  MdFeaturedPlayList
} from 'react-icons/md';
import {PermissionCode} from 'shared/constants/AppEnums';

const routesConfig = [
  {
    id: 'system',
    title: 'System',
    messageId: 'sidebar.systems',
    type: 'group',
    children: [
      {
        id: 'thong-tin-chung',
        title: 'General Infomation',
        messageId: 'sidebar.generalinfomation',
        type: 'collapse',
        icon: <MdOutlineSettingsApplications />,
        children: [
          {
            id: 'cong-an-tinh',
            title: 'Customer',
            messageId: 'sidebar.generalinfomation.customer',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/cong-an-tinh',
          },
          {
            id: 'bo-cuc',
            title: 'Layout',
            messageId: 'sidebar.generalinfomation.layout',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/bo-cuc',
          },
          {
            id: 'hoat-dong-noi-bat-cua-lanh-dao',
            title: 'Leader Activity',
            messageId: 'sidebar.generalinfomation.leaderActivity',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/hoat-dong-noi-bat-cua-lanh-dao',
          },
          {
            id: 'email-server',
            title: 'Email Server',
            messageId: 'sidebar.generalinfomation.emailServer',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/email-server',
          },
        ],
      },
      {
        id: 'category',
        title: 'Category',
        messageId: 'sidebar.category',
        type: 'collapse',
        icon: <MdOutlineDns />,
        children: [
          {
            id: 'cau-hinh',
            title: 'Configuration',
            messageId: 'sidebar.category.configuration',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/cau-hinh',
          },
          {
            id: 'banner',
            title: 'Banner',
            messageId: 'sidebar.category.banner',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/banner',
          },
          {
            id: 'slogan',
            title: 'Slogan',
            messageId: 'sidebar.category.slogan',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/slogan',
          },
          {
            id: 'lanh-dao-cong-an-tinh',
            title: 'Lead Cadres',
            messageId: 'sidebar.category.leadcadres',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/lanh-dao-cong-an-tinh',
          },
          {
            id: 'danh-ba',
            title: 'Contact',
            messageId: 'sidebar.category.contact',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/danh-ba',
          },
          {
            id: 'danh-muc',
            title: 'Category',
            messageId: 'sidebar.category.category',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/danh-muc',
          },
          {
            id: 'lien-ket',
            title: 'Link',
            messageId: 'sidebar.category.link',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/lien-ket',
          },
          {
            id: 'don-vi-dia-phuong-va-don-vi-truc-thuoc',
            title: 'Unit',
            messageId: 'sidebar.category.unit',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/don-vi-dia-phuong-va-don-vi-truc-thuoc',
          },
          {
            id: 'chinh-sach-dam-bao-atttcn',
            title: 'Security',
            messageId: 'sidebar.category.security',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/chinh-sach-dam-bao-atttcn',
          },
        ],
      },
      {
        id: 'media',
        title: 'Media Library',
        messageId: 'sidebar.media',
        type: 'collapse',
        icon: <MdPermMedia />,
        children: [
          {
            id: 'quan-ly-media',
            title: 'Thư viện',
            messageId: 'sidebar.media.library',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/quan-ly-media',
          },
        ],
      },
      // {
      //   id: 'administrative_procedure',
      //   title: 'Administrative Formalities',
      //   messageId: 'sidebar.administrative',
      //   type: 'collapse',
      //   icon: <MdOutlineHistoryEdu />,
      //   children: [
      //     {
      //       id: 'administrative_list',
      //       title: 'Danh sách thủ tục hành chính',
      //       messageId: 'sidebar.administrative.list',
      //       type: 'item',
      //       icon: <BiAlignLeft />,
      //       url: '/admin/tthc/thu-tuc-hanh-chinh',
      //     },
      //     {
      //       id: 'tin-bai-tthc',
      //       title: 'ArticleProcedure',
      //       messageId: 'sidebar.administrative.articleprocedure',
      //       type: 'item',
      //       icon: <BiAlignLeft />,
      //       url: '/admin/tin-bai-tthc',
      //     },
      //   ],
      // },
      {
        id: 'user',
        title: 'User management',
        messageId: 'sidebar.user',
        type: 'collapse',
        icon: <MdOutlineManageAccounts />,
        children: [
          {
            id: 'nguoi-dung',
            title: 'Danh sách người dùng',
            messageId: 'sidebar.user.list',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/nguoi-dung',
          },
          {
            id: 'quyen-nguoi-dung',
            title: 'Danh sách quyền',
            messageId: 'sidebar.user.role',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/quyen-nguoi-dung',
          },
          {
            id: 'thong-tin-tai-khoan',
            title: 'PersonalInformation',
            messageId: 'sidebar.personalinfo',
            type: 'item',
            hidden: true,
            icon: <BiAlignLeft />,
            url: '/admin/thong-tin-tai-khoan',
          },
        ],
      },
      {
        id: 'reflectRequest',
        title: 'Reflect Request',
        messageId: 'sidebar.reflect',
        type: 'collapse',
        icon: <MdMoveToInbox />,
        permission: PermissionCode.READ,
        children: [
          {
            id: 'phan-anh-kien-nghi-cau-hinh',
            title: 'Cấu hình',
            messageId: 'sidebar.reflect.config',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/phan-anh-kien-nghi-cau-hinh',
          },
          {
            id: 'linh-vuc-phan-anh',
            title: 'Danh mục lĩnh vực',
            messageId: 'sidebar.reflect.field',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/linh-vuc-phan-anh',
          },
          // {
          //   id: 'phan-anh-kien-nghi',
          //   title: 'Quản lý Phản ánh - Kiến nghị',
          //   messageId: 'sidebar.reflect.list',
          //   type: 'item',
          //   icon: <BiAlignLeft />,
          //   url: '/admin/phan-anh-kien-nghi',
          // },
          // {
          //   id: 'report-pakn',
          //   title: 'Thống kê',
          //   messageId: 'sidebar.reflect.report',
          //   type: 'item',
          //   icon: <BiAlignLeft />,
          //   url: '/admin/report-pakn',
          // },
        ],
      },
      {
        id: 'lawDissemination',
        title: 'Law Dissemination',
        messageId: 'sidebar.lawdissemination',
        type: 'collapse',
        icon: <MdLibraryBooks />,
        children: [
          {
            id: 'quan-ly-van-ban',
            title: 'Quản lý văn bản',
            messageId: 'sidebar.lawdissemination.document',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/quan-ly-van-ban',
          },
          {
            id: 'loai-van-ban',
            title: 'Loại văn bản',
            messageId: 'sidebar.lawdissemination.documenttype',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/loai-van-ban',
          },
          {
            id: 'co-quan-ban-hanh',
            title: 'Cơ quan ban hành',
            messageId: 'sidebar.lawdissemination.agencyissued',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/co-quan-ban-hanh',
          },
        ],
      },
      {
        id: 'manage-evaluation-category',
        title: 'Manage Evaluation',
        messageId: 'sidebar.categoryEvaluation',
        type: 'collapse',
        icon: <MdFeaturedPlayList />,
        children: [
          {
            id: 'qr-danh-gia',
            title: 'Quản lý QR đánh giá',
            messageId: 'sidebar.manageEvaluation.qr',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/qr-danh-gia',
          },
          {
            id: 'tieu-chi-danh-gia',
            title: 'Quản lý Tiêu chí đánh giá',
            messageId: 'sidebar.manageEvaluation.criteria',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/tieu-chi-danh-gia',
          },
          {
            id: 'muc-do-danh-gia',
            title: 'Mức độ',
            messageId: 'sidebar.manageEvaluation.level',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/muc-do-danh-gia',
          },
          // {
          //   id: 'thiet-bi-danh-gia',
          //   title: 'Thiết bị',
          //   messageId: 'sidebar.manageEvaluation.device',
          //   type: 'item',
          //   icon: <BiAlignLeft />,
          //   url: '/admin/thiet-bi-danh-gia',
          // },
          {
            id: 'don-vi-tthc',
            title: 'Đơn vị hành chính công',
            messageId: 'sidebar.manageEvaluation.unit',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/don-vi-tthc',
          },
          {
            id: 'doi-tthc',
            title: 'Đội hành chính công',
            messageId: 'sidebar.manageEvaluation.team',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/doi-tthc',
          },
        ],
      },
      {
        id: 'manage-evaluation',
        title: 'Manage Evaluation',
        messageId: 'sidebar.manageEvaluation',
        type: 'collapse',
        icon: <MdOutlineHistoryEdu />,
        children: [
          {
            id: 'quan-ly-danh-gia',
            title: 'Quản lý đánh giá',
            messageId: 'sidebar.manageEvaluation.manage',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/quan-ly-danh-gia',
          },
          {
            id: 'thong-ke-theo-don-vi',
            title: 'Thống kê theo đơn vị',
            messageId: 'sidebar.manageEvaluation.reportByUnit',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/thong-ke-theo-don-vi',
          },
          {
            id: 'thong-ke-theo-thoi-gian',
            title: 'Thống kê theo thời gian',
            messageId: 'sidebar.manageEvaluation.reportByTime',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/thong-ke-theo-thoi-gian',
          },
          {
            id: 'thong-ke-theo-luot-truy-cap',
            title: 'Thống kê theo luowit truy cập',
            messageId: 'sidebar.manageEvaluation.reportByAccess',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/thong-ke-theo-luot-truy-cap',
          },
          {
            id: 'thong-ke-theo-tieu-chi',
            title: 'Thống kê theo tiêu chí',
            messageId: 'sidebar.manageEvaluation.reportByCriteria',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/thong-ke-theo-tieu-chi',
          },
          {
            id: 'thong-ke-luot-truy-cap-qr',
            title: 'Thống kê theo lượt truy cập QR',
            messageId: 'sidebar.manageEvaluation.reportByQR',
            type: 'item',
            icon: <BiAlignLeft />,
            url: '/admin/thong-ke-luot-truy-cap-qr',
          },
        ],
      },
    ],
  },
  {
    id: 'tin-bai',
    title: 'Article',
    messageId: 'sidebar.article',
    type: 'group',
    url: '/admin/tin-bai',
    children: [
      {
        id: 'danh-sach',
        title: 'Danh sách',
        messageId: 'sidebar.article.list',
        type: 'item',
        icon: <MdOutlineArticle />,
        url: '/admin/tin-bai/danh-sach',
      },
      {
        id: 'tao-moi',
        title: 'Tạo mới',
        messageId: 'sidebar.article.add',
        type: 'item',
        icon: <MdCreate />,
        url: '/admin/tin-bai/tao-moi',
        permission: PermissionCode.CREATE,
      },
      {
        id: 'duyet',
        title: 'Duyệt tin bài',
        messageId: 'sidebar.article.approval',
        type: 'item',
        icon: <MdApproval />,
        url: '/admin/tin-bai/duyet',
        permission: PermissionCode.APPLY,
      },
    ],
  },
  {
    id: 'phan-anh-kien-nghi',
    title: 'Quản lý Phản ánh - Kiến nghị',
    messageId: 'sidebar.reflect.list',
    type: 'group',
    url: '/admin/phan-anh-kien-nghi',
    permission: PermissionCode.DELETE,
    children: [
      {
        id: 'phan-anh-cho-xu-ly',
        title: 'Phản ánh chờ xử lý',
        messageId: 'sidebar.reflect.process',
        type: 'item',
        icon: <MdHourglassBottom />,
        url: '/admin/phan-anh-kien-nghi/phan-anh-cho-xu-ly',
        permission: PermissionCode.DELETE,
      },
      {
        id: 'phan-anh-cho-duyet',
        title: 'Phản ánh chờ duyệt',
        messageId: 'sidebar.reflect.approve',
        type: 'item',
        icon: <MdAssignmentTurnedIn />,
        url: '/admin/phan-anh-kien-nghi/phan-anh-cho-duyet',
        permission: PermissionCode.DELETE,
      },
      {
        id: 'phan-anh-cong-khai',
        title: 'Phản ánh được công khai',
        messageId: 'sidebar.reflect.public',
        type: 'item',
        icon: <MdFeed />,
        url: '/admin/phan-anh-kien-nghi/phan-anh-cong-khai',
        permission: PermissionCode.DELETE,
      },
      {
        id: 'thong-ke-phan-anh-kien-nghi',
        title: 'Thống kê',
        messageId: 'sidebar.reflect.report',
        type: 'item',
        icon: <MdBarChart />,
        url: '/admin/phan-anh-kien-nghi/thong-ke-phan-anh-kien-nghi',
        permission: PermissionCode.DELETE,
      },
    ],
  },
  {
    id: 'administrative_procedure',
    title: 'Administrative Formalities',
    messageId: 'sidebar.administrative',
    type: 'group',
    icon: <MdOutlineHistoryEdu />,
    permission: PermissionCode.READ,
    children: [
      {
        id: 'cap-quan-ly-tthc',
        title: 'Managers Level',
        messageId: 'sidebar.category.managerslevel',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/admin/cap-quan-ly-tthc',
      },
      {
        id: 'giay-to-thu-tuc-hanh-chinh',
        title: 'ArticleProcedure',
        messageId: 'sidebar.administrative.articleprocedure',
        type: 'item',
        icon: <MdStickyNote2 />,
        url: '/admin/giay-to-thu-tuc-hanh-chinh',
        permission: PermissionCode.READ,
      },
      {
        id: 'danh-muc-thu-tuc-hanh-chinh',
        title: 'Danh mục Thủ tục hành chính',
        messageId: 'sidebar.administrative.list',
        type: 'item',
        icon: <MdWork />,
        url: '/admin/danh-muc-thu-tuc-hanh-chinh',
        permission: PermissionCode.READ,
      },
    ],
  },
  {
    id: 'cau-hoi',
    title: 'Online Inquiry',
    messageId: 'sidebar.onlineinquiry',
    type: 'group',
    url: '/admin/cau-hoi',
    icon: <MdOutlineHistoryEdu />,
    children: [
      {
        id: 'cau-hoi-da-xu-ly',
        title: 'Câu hỏi đã xử lý',
        messageId: 'sidebar.onlineinquiry.process',
        type: 'item',
        icon: <MdAssignmentTurnedIn />,
        url: '/admin/cau-hoi/cau-hoi-da-xu-ly',
        permission: PermissionCode.DELETE,
      },
      {
        id: 'cau-hoi-cho-xu-ly',
        title: 'Câu hỏi chờ xử lý',
        messageId: 'sidebar.onlineinquiry.approve',
        type: 'item',
        icon: <MdHourglassBottom />,
        url: '/admin/cau-hoi/cau-hoi-cho-xu-ly',
        permission: PermissionCode.DELETE,
      },
      {
        id: 'cau-hinh-hoi-dap',
        title: 'Cấu hình HĐTT',
        messageId: 'sidebar.onlineinquiry.config',
        type: 'item',
        icon: <BiAlignLeft />,
        url: '/admin/cau-hinh-hoi-dap',
        permission: PermissionCode.READ,
      },
    ],
  },
];
export default routesConfig;
