import React, {useState, useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppNotificationContent from './AppNotificationContent';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AppTooltip from '../AppTooltip';
import {alpha} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useSelector, useDispatch} from 'react-redux';
import Badge from '@mui/material/Badge';

const AppNotificationsReflect = ({
  drawerPosition,
  tooltipPosition,
  isMenu,
  sxNotificationContentStyle,
}) => {
  const [showNotification, setShowNotification] = useState(false);
  const {reflectNotificationNoFilter} = useSelector(
    ({reflectApp}) => reflectApp,
  );

  return (
    <>
      {isMenu ? (
        <Box component='span' onClick={() => setShowNotification(true)}>
          Message
        </Box>
      ) : (
        <>
          <AppTooltip title='Thông báo phản ánh' placement={tooltipPosition}>
            <IconButton
              className='icon-btn'
              sx={{
                borderRadius: '50%',
                width: 40,
                height: 40,
                color: (theme) => theme.palette.text.secondary,
                backgroundColor: (theme) => theme.palette.background.default,
                border: 1,
                borderColor: 'transparent',
                '&:hover, &:focus': {
                  color: (theme) => theme.palette.text.primary,
                  backgroundColor: (theme) =>
                    alpha(theme.palette.background.default, 0.9),
                  borderColor: (theme) =>
                    alpha(theme.palette.text.secondary, 0.25),
                },
              }}
              onClick={() => setShowNotification(true)}
              size='large'
            >
              <Badge
                badgeContent={reflectNotificationNoFilter?.length}
                color='secondary'
              >
                <NotificationsNoneIcon />
              </Badge>
            </IconButton>
          </AppTooltip>
          {/* {reflectNotificationNoFilter?.length > 0 && (
            <Typography
              sx={{
                color: 'red',
                fontWeight: 'bold',
                marginLeft: 0,
                zIndex: 99,
              }}
            >
              {reflectNotificationNoFilter?.length}
            </Typography>
          )} */}
        </>
      )}

      <Drawer
        anchor={drawerPosition}
        open={showNotification}
        onClose={() => setShowNotification(false)}
      >
        <AppNotificationContent
          sxStyle={sxNotificationContentStyle}
          onClose={() => setShowNotification(false)}
          reflectNotificationNoFilter={reflectNotificationNoFilter}
        />
      </Drawer>
    </>
  );
};

export default AppNotificationsReflect;

AppNotificationsReflect.defaultProps = {
  drawerPosition: 'right',
  tooltipPosition: 'bottom',
};

AppNotificationsReflect.propTypes = {
  drawerPosition: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isMenu: PropTypes.bool,
  sxNotificationContentStyle: PropTypes.object,
};
