import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {getToDay} from '@common/utility/FormatTime';
import {Fonts} from 'shared/constants/AppEnums';

function AppWeather(props) {
  const url = 'https://api.shecodes.io/weather/v1/current?';
  const apikey = '342951afb0fafa28b3f533o92049314t';

  const [city, setCity] = useState('Huế');
  const [weather, setWeather] = useState(null);

  const weatherURL = (city) => {
    let nameCity = city.toUpperCase();
    return `${url}&query=${nameCity}&key=${apikey}&lang=vi&units=metric`;
  };

  const getWeather = async (city) => {
    const response = await fetch(weatherURL(city));
    const data = await response.json();
    setWeather(data);
  };

  useEffect(() => {
    getWeather(city);
  }, []);
  return (
    <Box>
      {weather ? (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            maxHeight: 20,
            minWidth: 200,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              alignContent: 'center',
              py: 2,
            }}
          >
            <Typography
              variant='h5'
              color='text.secondary'
              sx={{fontSize: {xl: 13, lg: 11}}}
            >
              {'Huế'}
            </Typography>
            <Typography
              color='text.secondary'
              sx={{
                fontSize: {xl: 12, lg: 10},
                color: (theme) => theme.palette.secondary.mauExtension,
                fontWeight: Fonts.BOLD,
              }}
            >
              {getToDay()}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Avatar
              alt='icon'
              src={weather.condition.icon_url}
              sx={{width: 33, height: 33}}
            />
            <Box sx={{px: 1}} />
            <Typography
              variant='h5'
              color='text.secondary'
              sx={{fontSize: {xl: 13, lg: 12}}}
            >
              {Math.round(
                weather.temperature.feels_like || weather.temperature.current,
              )}{' '}
              °C
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

AppWeather.propTypes = {};

export default AppWeather;
