import React from 'react';
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropsTypes from 'prop-types';

const FooterNotFixedWrapper = ({children, ...rest}) => {
  return (
    <Box>
      <Box
        style={{
          position: 'relative',
          background:
            '#C7262E url(/assets/images/footer.png) center top no-repeat',
          paddingTop: '30px',
          fontSize: '13px',
        }}
        sx={{
          // marginBottom: '-58px',
          width: '100%',
          boxShadow: 'none',
          border: '1px solid #e8e5dd',
          backgroundColor: (theme) => theme.palette.secondary.mauExtension,
          backgroundImage: (theme) =>
            `linear-gradient(${alpha(
              theme.palette.common.white,
              0.05,
            )}, ${alpha(theme.palette.common.white, 0.05)})`,
          color: (theme) => theme.palette.grey['50'],
          '& .footerContainer': {
            alignItems: 'center',
            flexDirection: 'row',
            display: 'flex',
            padding: {xs: '5px 10px', md: '5px 32px', xl: '10px 0px'},
          },
          mx: 'auto',
        }}
        {...rest}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FooterNotFixedWrapper;

FooterNotFixedWrapper.propTypes = {
  children: PropsTypes.node,
  sidebarHeaderColor: PropsTypes.string,
};
