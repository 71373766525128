import React from 'react';
import ContentLoader from 'react-content-loader';

const MenuItemSkeleton = (props) => (
  <ContentLoader
    speed={1}
    width={200}
    height={40}
    viewBox='0 0 200 40'
    {...props}
  >
    <rect x='9' y='4' rx='0' ry='0' width='320' height='22' />
    <rect x='18' y='14' rx='0' ry='0' width='303' height='6' />
    <rect x='11' y='33' rx='0' ry='0' width='108' height='13' />
    <rect x='129' y='33' rx='0' ry='0' width='60' height='13' />
    <rect x='196' y='33' rx='0' ry='0' width='60' height='13' />
  </ContentLoader>
);

const MenuHomeSkeleton = (props) => (
  <React.Fragment>
    <MenuItemSkeleton {...props} />
    <MenuItemSkeleton {...props} />
    <MenuItemSkeleton {...props} />
    <MenuItemSkeleton {...props} />
    <MenuItemSkeleton {...props} />
  </React.Fragment>
);

export default MenuHomeSkeleton;
