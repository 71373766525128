import React from 'react';
import notification from '@common/services/db/notifications';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import AppScrollbar from '@common/core/AppScrollbar';
import IntlMessages from '@common/utility/IntlMessages';
import NotificationItem from './NotificationItem';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NextLink from 'next/link';

const AppNotificationContent = ({
  onClose,
  sxStyle,
  reflectNotificationNoFilter,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 320,
        height: '100%',
        ...sxStyle,
      }}
    >
      {reflectNotificationNoFilter &&
      reflectNotificationNoFilter?.length > 0 ? (
        <>
          <Box
            sx={{
              padding: '0px 20px',
              display: 'flex',
              alignItems: 'center',
              borderBottom: 1,
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
              borderBottomColor: (theme) => theme.palette.primary.main,
              height: 50,
              // minHeight: {xs: 56, sm: 70},
            }}
          >
            <Typography component='h3' variant='h3'>
              Phản ánh kiến nghị
            </Typography>
            <IconButton
              sx={{
                height: 40,
                width: 40,
                marginLeft: 'auto',
                color: 'white',
              }}
              onClick={onClose}
              size='large'
            >
              <CancelOutlinedIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              padding: '5px 20px',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
              borderBottomColor: (theme) => theme.palette.primary.main,
            }}
          >
            <NotificationsActiveIcon />({reflectNotificationNoFilter?.length})
          </Box>
          <AppScrollbar
            sx={{
              height: {xs: 'calc(100% - 96px)', sm: 'calc(100% - 110px)'},
            }}
          >
            <List sx={{py: 0}}>
              {reflectNotificationNoFilter.map((item) => (
                <NotificationItem key={item.id} item={item} />
              ))}
            </List>
          </AppScrollbar>
          {reflectNotificationNoFilter?.length > 0 && (
            // <Link
            //   sx={{
            //     all: 'unset',
            //     cursor: 'pointer',
            //   }}
            //   href={`/phan-anh-thong-bao`}
            // >
            //   <a style={{all: 'unset'}}>
            <NextLink href={`/phan-anh-thong-bao`}>
              <Link
                sx={{
                  all: 'unset',
                  cursor: 'pointer',
                }}
                href={`/phan-anh-thong-bao`}
              >
                <Button
                  sx={{
                    borderRadius: 0,
                    width: '100%',
                    textTransform: 'capitalize',
                    marginTop: 'auto',
                    height: 40,
                  }}
                  variant='contained'
                  color='primary'
                >
                  <IntlMessages id='common.viewAll' />
                </Button>
              </Link>
            </NextLink>
          )}
        </>
      ) : (
        <Box
          sx={{
            padding: '5px 20px',
            display: 'flex',
            alignItems: 'center',
            minHeight: {xs: 56, sm: 70},
          }}
        >
          <Typography component='h3' variant='h3'>
            Không có thông báo
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AppNotificationContent;

AppNotificationContent.propTypes = {
  onClose: PropTypes.func,
  sxStyle: PropTypes.object,
  reflectNotificationNoFilter: PropTypes.any,
};
