import React from 'react';
import {useRouter, withRouter} from 'next/router';
import clsx from 'clsx';
import Link from 'next/link';
import PropTypes from 'prop-types';
import IntlMessages from '../../../../utility/IntlMessages';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import PropsTypes from 'prop-types';

import Icon from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Fonts} from 'shared/constants/AppEnums';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';

function HorizontalItem(props) {
  const {item, dense} = props;
  const {pathname, asPath} = useRouter();
  const dispatch = useDispatch();
  const active = isUrlInChildren(item, pathname);
  const {sidebarMenuSelectedBgColor, sidebarMenuSelectedTextColor} =
    useSidebarContext();
  const breadcrumbs = useSelector(({common}) => common.breadcrumbs);
  function isUrlInChildren(parent, url) {
    if (!parent.children) {
      return false;
    }

    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].children) {
        if (isUrlInChildren(parent.children[i], url)) {
          return true;
        }
      }

      if (
        parent.children[i].url === url ||
        url.includes(parent.children[i].url)
      ) {
        return true;
      }
    }

    return false;
  }

  const isActiveArticle = () => {
    if (asPath.includes('/bai-viet/')) {
      if (Array.isArray(breadcrumbs) && breadcrumbs.length > 0) {
        const result = breadcrumbs.filter((x) => x.slug === item.originSlug);
        if (result.length > 0) {
          return true;
        }
      }
    }
    return false;
  };
  return (
    <Link href={item.url} as={item.as}>
      <a
        style={{
          all: 'unset',
        }}
      >
        <ListItem
          className={clsx('navItemSubmenu', dense && 'dense', {
            active: item.url === asPath || isActiveArticle(),
          })}
          button
          exact={item.exact}
          sx={{
            cursor: 'pointer',
            minHeight: 40,
            padding: '4px 12px',
            color: (theme) => theme.palette.text.primary,
            textDecoration: 'none!important',
            minWidth: 160,
            '&.active, &.active:hover, &.active:focus': {
              backgroundColor: sidebarMenuSelectedBgColor + '!important',
              color: sidebarMenuSelectedTextColor + '!important',
            },
            '&.active': {
              backgroundColor: sidebarMenuSelectedBgColor,
              color: sidebarMenuSelectedTextColor + '!important',
              pointerEvents: 'none',
              '& .list-item-text-primary': {
                color: 'inherit',
              },
              '& .list-item-icon': {
                color: 'inherit',
              },
            },
            '& .list-item-text': {
              padding: '0 0 0 16px',
            },
            '&.dense': {
              padding: '4px 12px',
              minHeight: 40,
              '& .list-item-text': {
                padding: '0 0 0 8px',
              },
            },
          }}
        >
          {item.icon && (
            <Icon
              sx={{
                color: active ? sidebarMenuSelectedTextColor : 'action',
                mr: 3,
                fontSize: {xs: 16, xl: 18},
              }}
            >
              {item.icon}
            </Icon>
          )}
          <ListItemText
            className='AppNavLinkTextSubmenu'
            // primary={<IntlMessages id={item.messageId} />}
            primary={item.title}
            primaryTypographyProps={{fontWeight: Fonts.BOLD}}
          />
          {item.count && (
            <Box ml={4} clone>
              <Badge
                count={item.count}
                sx={{
                  color: item.color,
                }}
              />
            </Box>
          )}
        </ListItem>
      </a>
    </Link>
  );
}

HorizontalItem.defaultProps = {};

export default withRouter(React.memo(HorizontalItem));

HorizontalItem.propTypes = {
  item: PropTypes.object,
  dense: PropTypes.bool,
  router: PropsTypes.object,
};
