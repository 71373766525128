import {MailDetailSkeleton} from '@common/core/AppSkeleton/MailDetailSkeleton';
import dynamic from 'next/dynamic';
import React from 'react';
import {AppLoader} from '../index';

export default function asyncComponent(importComponent, isHome) {
  return dynamic(importComponent, {
    // loading: () => (!!isHome ? <MailDetailSkeleton /> : <AppLoader />),
    loading: () => <AppLoader />,
  });
}
