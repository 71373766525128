import React from 'react';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import {Fonts} from '../../../shared/constants/AppEnums';
import {
  colorStatus,
  numberStatus,
} from '../../../@common/utility/AppContextProvider/defaultConfig';
import Link from '@mui/material/Link';
import NextLink from 'next/link';

const NotificationItem = (props) => {
  const {item} = props;

  return (
    // <Link
    //   sx={{
    //     all: 'unset',
    //     cursor: 'pointer',
    //   }}
    //   href={`/chi-tiet-phan-anh/${item.slug}`}
    // >
    //   <a style={{all: 'unset'}}>
    <NextLink href={`/chi-tiet-phan-anh/${item.slug}`}>
      <Link
        sx={{
          all: 'unset',
          cursor: 'pointer',
        }}
        href={`/chi-tiet-phan-anh/${item.slug}`}
      >
        <ListItem
          sx={{
            padding: '4px 20px',
          }}
          className='item-hover'
        >
          <Box
            sx={{
              fontSize: 14,
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            <Typography>
              <Box
                component='span'
                sx={{
                  fontSize: 14,
                  fontWeight: Fonts.MEDIUM,
                  mb: 0,
                  color: (theme) => theme.palette.text.primary,
                  mr: 1,
                  display: 'inline-block',
                  cursor: 'pointer',
                  '&:hover': {
                    color: (theme) => theme.palette.secondary.mauExtension,
                  },
                }}
              >
                {item.phanAnhKienNghi}
              </Box>
              <div
                style={
                  item.maTrangThai === numberStatus.choTiepNhan
                    ? {color: colorStatus.choTiepNhan}
                    : item.maTrangThai === numberStatus.daTiepNhan
                    ? {color: colorStatus.daTiepNhan}
                    : item.maTrangThai === numberStatus.daXuLy
                    ? {color: colorStatus.daXuLy}
                    : {color: colorStatus.daTuChoi}
                }
              >
                {item.thongBao}
              </div>
            </Typography>
          </Box>
        </ListItem>
        <Divider color='#C7262E' />
      </Link>
    </NextLink>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
};
