import React, {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import routesConfig from 'modules/routesConfig';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import NextLink from 'next/link';

const getPathFromUrl = (url) => {
  return url.split(/[?#]/)[0];
};

//Array method
const recursivelyFindKeyValue = (key, keyValue, list, depth = 0) => {
  let results = [];

  for (let i = 0; i < list.length; i++) {
    const item = list[i];

    for (const key of Object.keys(item)) {
      //check if its not an array
      if (Array.isArray(item[key])) {
        let res = recursivelyFindKeyValue(key, keyValue, item[key], depth + 1);
        results = results.concat(res);
      }
      //we have found it
      else if (item[key] === keyValue) {
        //found, return the list

        //return { found: true, containingArray: list, depth: depth };
        results.push({
          found: true,
          containingArray: list,
          depth: depth,
          object: item,
        });
      }
    }
  }

  return results;
};

const AppBreadcrumbs = ({
  useDefaultStyle,
  rootLabel,
  omitRootLabel,
  labelsToUppercase,
  replaceCharacterList,
  transformLabel,
  omitIndexList,
  containerStyle,
  containerClassName,
  listStyle,
  listClassName,
  inactiveItemStyle,
  inactiveItemClassName,
  activeItemStyle,
  activeItemClassName,
  separator,
}) => {
  const router = useRouter();
  const [AppBreadcrumbs, setAppBreadcrumbs] = useState(null);
  const {messages} = useIntl();

  const convertBreadcrumb = useCallback(
    (title, toUpperCase, replaceCharacterList, transformLabel) => {
      let transformedTitle = getPathFromUrl(title);
      if (transformLabel) {
        const res = recursivelyFindKeyValue(
          'id',
          transformedTitle,
          routesConfig,
        );
        if (Array.isArray(res) && res.length > 0) {
          return messages[res[0].object.messageId];
        } else if (transformedTitle === 'admin') {
          return messages['breadcrumb.admin'];
        }
        return transformLabel(transformedTitle);
      }

      if (replaceCharacterList) {
        for (let i = 0; i < replaceCharacterList.length; i++) {
          transformedTitle = transformedTitle.replaceAll(
            replaceCharacterList[i].from,
            replaceCharacterList[i].to,
          );
        }
      }

      // decode for utf-8 characters and return ascii.
      return toUpperCase
        ? decodeURI(transformedTitle).toUpperCase()
        : decodeURI(transformedTitle);
    },
    [messages, useIntl],
  );

  useEffect(() => {
    if (router) {
      const linkPath = router.asPath.split('/');
      linkPath.shift();

      const pathArray = linkPath.map((path, i) => {
        return {
          breadcrumb: path,
          href: '/' + linkPath.slice(0, i + 1).join('/'),
        };
      });

      setAppBreadcrumbs(pathArray);
    }
  }, [router]);

  if (!AppBreadcrumbs) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        p: 2,
        bgcolor: '#ffffff',
        alignItems: 'center',
        borderBottom: '1px solid #e8e8e8',
      }}
      direction='row'
    >
      <Breadcrumbs
        style={containerStyle}
        className={containerClassName}
        aria-label='breadcrumb'
        separator={separator}
      >
        {!omitRootLabel && (
          <NextLink href='/'>
            <Link
              color='inherit'
              underline='hover'
              textTransform='capitalize'
              sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
            >
              <HomeIcon sx={{mr: 0.5}} fontSize='medium' />
              {convertBreadcrumb(
                rootLabel || 'Home',
                labelsToUppercase,
                replaceCharacterList,
                transformLabel,
              )}
            </Link>
          </NextLink>
        )}
        {AppBreadcrumbs.length >= 1 &&
          AppBreadcrumbs.map((breadcrumb, i) => {
            if (
              !breadcrumb ||
              breadcrumb.breadcrumb.length === 0 ||
              (omitIndexList && omitIndexList.find((value) => value === i))
            ) {
              return;
            }
            return (
              <NextLink key={breadcrumb.href} href={breadcrumb.href + '/'}>
                <Link
                  color='inherit'
                  underline='hover'
                  textTransform='capitalize'
                  sx={{cursor: 'pointer'}}
                  href={breadcrumb.href}
                >
                  <Typography color='primary' textTransform='capitalize'>
                    {convertBreadcrumb(
                      breadcrumb.breadcrumb,
                      labelsToUppercase,
                      replaceCharacterList,
                      transformLabel,
                    )}
                  </Typography>
                </Link>
              </NextLink>
            );
          })}
      </Breadcrumbs>
    </Box>
  );
};
AppBreadcrumbs.propTypes = {
  useDefaultStyle: PropTypes.bool,
  rootLabel: PropTypes.string | null,
  omitRootLabel: PropTypes.bool,
  labelsToUppercase: PropTypes.bool,
  replaceCharacterList: PropTypes.array,
  transformLabel: PropTypes.any,
  omitIndexList: PropTypes.array,
  containerStyle: PropTypes.any,
  containerClassName: PropTypes.string,
  listStyle: PropTypes.any,
  listClassName: PropTypes.any,
  inactiveItemStyle: PropTypes.any,
  inactiveItemClassName: PropTypes.string,
  activeItemStyle: PropTypes.any,
  activeItemClassName: PropTypes.string,
  separator: PropTypes.any,
};

const defaultProps = {
  useDefaultStyle: false,
  rootLabel: 'Home',
  omitRootLabel: false,
  labelsToUppercase: false,
  replaceCharacterList: [{from: '-', to: ' '}],
  transformLabel: undefined,
  omitIndexList: undefined,
  containerStyle: null,
  containerClassName: '',
  listStyle: null,
  listClassName: '',
  inactiveItemStyle: null,
  inactiveItemClassName: '',
  activeItemStyle: null,
  activeItemClassName: '',
  separator: <ChevronRightOutlinedIcon />,
};

AppBreadcrumbs.defaultProps = defaultProps;

export default AppBreadcrumbs;
